import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { useFeedbackQContext } from '../context/FeedbackQ';
import Dashboard from './Dashboard';
import { fetchDataSources } from '../api/API';
import SkeletonDashboard from './utils/shimmerUI/SkeletonDashboard';

const TitleSection = () => {
    const { hasApps, setHasApps } = useFeedbackQContext();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const checkApps = async () => {
            // setLoading(true);
            const dashboard_id = localStorage.getItem("dashboardId");
            console.log("titleadsa:: ", dashboard_id);

            try {
                const fetchData = await fetchDataSources(dashboard_id);
                console.log(fetchData);

                if (fetchData.datasources.length > 0) {
                    setHasApps(true);
                } else {
                    setHasApps(false);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error occured while getting data source ', error);
            } finally {
                setLoading(false);
            }
        }
        checkApps()
    }, []);
    console.log(hasApps);

    return (
        <>
            {loading ? (
                <SkeletonDashboard />
            ) : !hasApps && (
                <Box sx={{
                    flexGrow: 1,
                    p: 3,
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    marginTop: "0%",
                    textAlign: "center",
                    // backgroundColor: "#f9f9f9",
                    minHeight: "90vh",
                }}
                >
                    <Paper sx={{
                        p: { xs: 3, sm: 4, md: 5 },
                        // backgroundColor: "#fff",
                        backgroundColor: "#37517e",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        maxWidth: "800px",
                        width: "100%",
                    }}
                    >
                        <Typography variant="h3" component="h1" gutterBottom sx={{ color: '#fff', fontWeight: 700 }}>
                            FeedbackQ
                        </Typography>
                        <Typography variant="h5" component="p" sx={{ mt: 2, mb: 6, color: '#ffffff99', fontSize: { xs: '20px', sm: '24px' }, fontWeight: 500 }}>
                            Welcome to Your Feedback Engine Co-pilot!
                        </Typography>
                        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} md={11}>
                                {/* <Typography variant="body1" component="p" sx={{ mb: 2, color: 'text.secondary' , textAlign: 'left'}}>
                                    <strong>Empower Your Insights</strong><br />
                                    Unlock the potential of your feedback with AI-powered analysis and prioritization. Your journey to smarter decision-making starts here!
                                </Typography> */}

                                <Typography
                                    variant="h5" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center' }}
                                >
                                    <strong>Empower Your Insights</strong>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Unlock the potential of your feedback with AI-powered analysis and prioritization. Your journey to smarter decision-making starts here!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                {/* <Typography variant="body1" component="p" sx={{ mb: 2, color: 'text.secondary', textAlign: 'left' }}> */}
                                <Typography
                                    variant="h5" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center' }}
                                >
                                    <strong>Seamless Integration</strong><br />
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Effortlessly manage and analyze feedback from multiple sources. Keep track of what's important and drive your product forward with data-driven insights.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <Typography variant="body1" component="p" sx={{ color: 'text.secondary' , textAlign: 'left'}}> */}
                                <Typography
                                    variant="h6" // You can use other variants if preferred
                                    component="p"
                                    sx={{ mb: 2, color: '#fff', textAlign: 'center', background: '#47b2e4', borderRadius: 50, px: 4, py: 2, width: 200 }}
                                >

                                    <Link to="/source" style={{ textDecoration: 'none', color: '#fff' }}>
                                        Get Started<br />
                                    </Link>

                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ mb: 2, color: '#ffffff99', textAlign: 'left', px: { xs: 2, sm: 4 } }}
                                >
                                    Navigate through our intuitive interface to begin categorizing, prioritizing, and making the most of your feedback. Your co-pilot is here to guide you every step of the way!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            )}
            {hasApps &&
                <Dashboard />
            }
        </>
    );
};

export default TitleSection;
