import { Box, Skeleton } from '@mui/material';

const SkeletonSection = ({ loading }) => {
    if (!loading) return null;

    return (
        <Box className='flex-1 border border-gray-300 rounded-lg shadow-md max-h-[400px] px-3'>
            <Skeleton variant="text" width="60%" height={40} className='p-4' />
            <Box className='p-4'>
                <Skeleton variant="rectangular" height={50} className='mb-4' />
                <Skeleton variant="rectangular" height={50} className='mb-4' />
                <Skeleton variant="rectangular" height={50} className='mb-4' />
                <Skeleton variant="rectangular" height={50} className='mb-4' />
            </Box>
        </Box>
    );
};

export default SkeletonSection;