import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CustomDatePicker from '../utils/CustomDatePicker';

const DateRangeDialog = ({ open, onClose, dateRange, onDateRangeChange, onApply, showCalendar, setShowCalendar }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Select Date Range</DialogTitle>
            <DialogContent>
                <Box className="relative w-fit h-[500px]">
                   <CustomDatePicker value={dateRange} onChange={onDateRangeChange} onApply={onApply} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DateRangeDialog;
