import React from "react";
import { Button } from "@mui/material";
import RulesForm from "./RuleForm";
import { BlockContainer, BlockTitle, ActionArea } from "../../muitheme/StyledComponents";

const AnalysisBlock = ({
  title,
  rules,
  setTitle,
  setRules,
  handleSubmit,
  showDetails,
  isEdit
}) => {

  return (
    <BlockContainer>
        <BlockTitle variant="h4" gutterBottom>
            What do you want to analyze?
        </BlockTitle>
        <RulesForm 
            title={title}
            rules={rules}
            setTitle={setTitle}
            setRules={setRules}
        />
        <ActionArea>
            <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            disabled={showDetails && !isEdit ? true : false}
            >
            Proceed
            </Button>
        </ActionArea>
    </BlockContainer>
  );
};

export default AnalysisBlock;