import React, { useState } from 'react';
import { Typography, Box, Tooltip, IconButton, Snackbar, Alert } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const URLBlock = ({ url }) => {
    const [open, setOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box className="flex items-center bg-white p-4 rounded-lg shadow-md min-w-0 mx-2">
                <Box className="flex-1 min-w-0">
                    <Typography variant="h6" className="text-gray-800">
                        URL:
                    </Typography>
                    <Box className="flex items-center bg-white p-2 rounded-lg shadow-sm border border-gray-300 mt-1">
                        <Typography variant="body1" className="font-mono text-blue-600 truncate">
                            {url}
                        </Typography>
                    </Box>
                </Box>
                <Tooltip title="Copy to clipboard">
                    <IconButton onClick={handleCopy} color="primary" aria-label="copy-url">
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="URL copied to clipboard"
            >
                <Alert onClose={handleClose} severity="success">
                    URL copied to clipboard
                </Alert>
            </Snackbar>
        </>
    );
};

export default URLBlock;
