import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const SidebarListItem = ({ icon, listItem, onClick }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton sx={{paddingLeft: "20px"}} onClick={onClick}>
                {icon && <ListItemIcon sx={{ color: "black" }}>
                    {icon}
                </ListItemIcon>}
                <ListItemText primary={listItem} sx={{ color: "#37517e" }} />
            </ListItemButton>
        </ListItem>
    );
};

export default SidebarListItem;
