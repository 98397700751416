import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {
  ListItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SidebarListItem from "../SidebarListItem";
import Icons from "../Icons";
import { Link } from "react-router-dom";
import PlayGroundDropDown from "../Dashboard/PlayGround";
import logo from '../../assets/img/logo.png';
// import ReviewsLoader from './Loaders/FetchingReviewsLoader'; //loader
// import { useFeedbackQContext } from "../context/FeedbackQ";
import { useFeedbackQContext } from "../../context/FeedbackQ";
import AnalyzeReviewsProgress from '../Loaders/AnalyzeReviewsProgress'; //progress
import AccountInfoDrawer from "../AccountInfo/AccountInfoDrawer";
import ReviewsLoader from "../Loaders/FetchingReviewsLoader"; //loader
import FetchingReviewsLoader from "../svg/FetchReviewLoader";
import AnalyseReviewLoader from "../svg/AnalyseReviewLoader";
// import { useFeedbackQContext } from "../context/FeedbackQ";
import { DrawerHeader, DrawerFooter, StyledInputBase } from "../../muitheme/StyledComponents";


const SideBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // const [loading,setLoading] = React.useState(true) ;
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopAnalyzing = () => {
    setIsAnalyzing(false);
    setProgressMessage('Analyzing reviews stopped.');
  };
 

  const { isAnalyzing,
    setIsAnalyzing,
    progressMessage,
    setProgressMessage,
    loading,
    name,
    setName
   } = useFeedbackQContext();
  // setLoadingData("Fetching Reviews from play store. Please wait..");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
            color: "black",
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "#37517e", // Light background color
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for depth
            mb: 2, // Margin-bottom to separate from other content
          }}
        >
          <img src={logo} alt="Logo" style={{ height: "40px", width: "auto" }} />
        </Box>
        <DrawerHeader>
          <Typography
            variant="div"
            sx={{ marginLeft: "5px", display: "flex", gap: "10px", alignItems: "center" }}
          >
            {/* <Avatar {...stringAvatar(name)} sx={{ width: 24, height: 24 }} /> */}
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px", // adjust the width as necessary
                fontWeight: 700,
                color: '#37517e',
                align: 'center'
              }}
            >
              {name}
            </Typography>
          </Typography>


          <UnfoldMoreIcon
            style={{ marginRight: "0px", cursor: "pointer" }}
            id="dropdown-button"
            aria-controls={anchorEl ? "dropdown-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={handleClick}
          />
        </DrawerHeader>
        <List>
          <ListItem>
            <StyledInputBase
              endAdornment={
                <SearchIcon sx={{ marginRight: 0, color: "black" }} />
              }
              placeholder="Search…"
            />
          </ListItem>
          <Link to="/" style={{ textDecoration: "none", color: '#37517e' }}>
            <SidebarListItem icon={Icons["homeIcon"]} listItem={"Home"} />
          </Link>
          {/* <Link
            to="/source"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidebarListItem
              icon={Icons["inboxIcon"]}
              listItem={"Data sources"}
            />
          </Link> */}
          <Link
            to="/reviews"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["snippet"]} listItem={"All reviews"} />
          </Link>
          <Link
            to="/themes"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["themes"]} listItem={"Themes"} />
          </Link>
          <Link
            to="/ask-q"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["askQ"]} listItem={"AskQ"} />
          </Link>
          <Link
            to="/rules"
            style={{ textDecoration: "none", color: "#37517e" }}
          >
            <SidebarListItem icon={Icons["rules"]} listItem={"Rules"}  />
          </Link>
        </List>
        <Box sx={{ width: '100%', flexGrow: 1 }} />
        <Box sx={{ width: '100%', height: '150px', position: 'absolute', bottom: "140px" }}>
          {/* progress */}
          {isAnalyzing && (
            <AnalyzeReviewsProgress
              message={progressMessage}
              isAnalyzing={isAnalyzing}
              onStop={handleStopAnalyzing}
            />
            // <AnalyseReviewLoader />
          )}
        </Box>
        {/* <DrawerFooter>
          
          <IconButton sx={{ color: "black", gap: "5px" }} />
        </DrawerFooter> */} 
       
        <Box sx={{ flexGrow: .9 }} />{/* Spacer to push the Data sources link to the bottom */}
         <Box> 
        {loading && <ReviewsLoader message="Fetching reviews..." />}
        {/* { loading && <FetchingReviewsLoader />} */}
        </Box>
        <Link to="/source" style={{ textDecoration: "none", color: "#37517e" }}>
          <SidebarListItem icon={Icons["inboxIcon"]} listItem={"Data sources"} />
        </Link>
       {/* earlier here was a footer */}
        <SidebarListItem
          icon={Icons["accInfo"]}
          listItem={"Usage"}
          onClick={toggleDrawer(true)}
        />

        {/* <DrawerFooter>
          <ProfileAvatar name={name} />
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton sx={{ color: "black" }}>
              <HelpOutlineIcon />
            </IconButton>
            <SettingsMenu />
          </Box>
        </DrawerFooter> */}
      </Drawer>
      <PlayGroundDropDown
        anchorEl={anchorEl}
        handleClose={handleClose}
        setName={setName}
      />
      <AccountInfoDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </Box>
  );
};


export default SideBar;
