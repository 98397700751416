import React from "react";
import { Box, Card, CardContent, CardActions, Button, Typography, TextField } from "@mui/material";
import HeaderTriggerPoint from "./HeaderTriggerPoint";

const RulesPrompt = ({ rulesPrompt, handleSubmit, headerTrigger, onHeaderChange, showSample, isEdit }) => {
    console.log("rulesPrompt:: ", rulesPrompt);
    
    const onProceed = ()=>{
        handleSubmit();
    }
    return (
        <Card className="flex-1 p-4">
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Rules Prompt
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    value={rulesPrompt}
                />
                <Box className='my-2'>
                    <HeaderTriggerPoint selectedValue={headerTrigger} onChange={onHeaderChange} />
                </Box>
            </CardContent>
            <CardActions className="justify-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onProceed}
                    disabled={showSample && !isEdit ? true : false}
                >
                    Proceed
                </Button>
            </CardActions>
        </Card>
    );
};

export default RulesPrompt;