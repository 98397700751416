import { Drawer, Box, Typography, Chip, Rating } from '@mui/material';
import { getSentimentDetails } from '../../helper/selectSentimentIcon';
import { useEffect, useState } from 'react';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import appstoreIcon from '../../assets/icons/appstore.png';
import playstoreIcon from '../../assets/icons/game.png';
import csvIcon from '../../assets/icons/csv-icon.png';
import apiIcon from '../../assets/icons/api-icon.png';
import unknownIcon from '../../assets/icons/unknown-mail.png';

const ReviewDrawer = ({ open, onClose, review }) => {
    const [sentimentDetails, setSentimentDetails] = useState({
        icon: <SentimentNeutralIcon color="action" />,
        color: 'action',
    });
    let reviewSource;
    const platformIcon = review?.source === 'play_store' ? 
        playstoreIcon : review?.source === 'app_store' ?
        appstoreIcon : review?.source === 'csv' ?
        csvIcon : review?.source === 'api' ?
        apiIcon : unknownIcon;
        reviewSource = review?.source === 'play_store' ? 
        'Play Store' : review?.source === 'app_store' ?
        'App Store' : review?.source === 'csv' ?
        'CSV' : review?.source === 'api' ?
        'API' : 'Unknown';
    useEffect(() => {

        if (review && review.sentiment) {
            const sentimentData = getSentimentDetails(review.sentiment);
            setSentimentDetails(sentimentData);
        } else {
            setSentimentDetails({
                icon: <SentimentNeutralIcon color="action" />,
                color: 'action',
            });
        }
    }, [review]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '70%',
                    padding: '16px',
                },
            }}
        >
            <Box position="relative" p={4}>
                {review ? (
                    <Box className="flex flex-col md:flex-row gap-4">
                        <Box className="flex-1 border rounded-lg p-4 shadow-md">
                            <Typography variant="h6" className="mb-4 font-semibold">
                                Review Details
                            </Typography>
                            <Typography variant="body1" className="mb-4">
                                {review.review}
                            </Typography>
                            <Box className="mt-4">
                                <Box className="flex items-center mb-2">
                                    <Typography variant="body2" className="font-semibold w-1/4">
                                        Category:
                                    </Typography>
                                    <Chip label="Set Category" color="primary" className="ml-2" />
                                </Box>
                                <Box className="flex items-center mb-2">
                                    <Typography variant="body2" className="font-semibold w-1/4">
                                        Sentiment:
                                    </Typography>
                                    <Box className="flex items-center ml-2">
                                        {sentimentDetails.icon}
                                        <Typography variant="body2" color={sentimentDetails.color} className="ml-1">
                                            {review.sentiment || 'Unknown'}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="flex items-center mb-2">
                                    <Typography variant="body2" className="font-semibold w-1/4">
                                        Project Area:
                                    </Typography>
                                    <Chip label="User Experience" color="default" className="ml-2" />
                                </Box>
                                <Box className="flex items-center mb-2">
                                    <Typography variant="body2" className="font-semibold w-1/4">
                                        Tags:
                                    </Typography>
                                    <Chip label="Add Tag" color="default" className="ml-2" />
                                </Box>
                            </Box>
                        </Box>
                        <Box className="w-full md:w-1/3 border rounded-lg p-4 shadow-md bg-white">
                            <Typography variant="h6" className="mb-4 font-semibold">
                                Review Info
                            </Typography>
                            <Box className="mb-2">
                                <Typography variant="body2" className="font-semibold">
                                    <strong>Platform:</strong>
                                </Typography>
                                <Box className="flex items-center">
                                    <img src={platformIcon} alt={review?.platform} className="w-6 h-6" />
                                    <Typography variant="body1" className="ml-3 pl-1">
                                        {reviewSource}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="mb-2">
                                <Typography variant="body2" className="font-semibold">
                                    <strong>Sentiment:</strong>
                                </Typography>
                                <Box className="flex items-center">
                                    {sentimentDetails.icon}
                                    <Typography variant="body1" color={sentimentDetails.color} className="ml-1">
                                        {review?.sentiment || 'Unknown'}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="mb-2">
                                <Typography variant="body2" className="font-semibold">
                                    <strong>Ratings:</strong>
                                </Typography>
                                <Box className="flex items-center">
                                    <Rating
                                        name="rating"
                                        value={review?.ratings || 0}
                                        readOnly
                                        precision={0.5}
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        <Box className="mb-2">
                            <Typography variant="body2" className="font-semibold">
                                <strong>Username:</strong>
                            </Typography>
                            <Typography variant="body1">{review?.user || 'Anonymous'}</Typography>
                        </Box>
                        <Box className="mb-2">
                            <Typography variant="body2" className="font-semibold">
                                <strong>Date:</strong>
                            </Typography>
                            <Typography variant="body1">{review?.date || 'N/A'}</Typography>
                        </Box>
                    </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">No review data available</Typography>
                )}
            </Box>
        </Drawer>
    );
};

export default ReviewDrawer;
