import { Card, CardContent, CardActions, Button, Typography } from "@mui/material";
import MarkdownContainer from "../Markdown/MarkdownContainer";

const SampleOutput = ({ sampleOutput, submitCreateRule }) => {
    return (
        <Card className="flex-1 p-4">
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Sample Output
                </Typography>
                <MarkdownContainer
                    content={sampleOutput}
                />
            </CardContent>
            <CardActions className="justify-end">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitCreateRule}
                >
                    Accept
                </Button>
            </CardActions>
        </Card>
    );
};

export default SampleOutput;