import React from 'react';
import { Box, Typography } from '@mui/material';

const ImageBlock = ({ title, count, percentage }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: { xs: '100%', sm: '48%', md: '310px' },
            height: '150px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            margin: '0 20px',
            backgroundColor: '#fff',
            color: '#333',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}>
            <Typography variant="h4" component="div" gutterBottom>{title}</Typography>
            <Typography variant="h4" component="div">{count}</Typography>
            <Typography variant="caption" component="div">{percentage} of Themes</Typography>
        </Box>
    );
};

export default ImageBlock;
