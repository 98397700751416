import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Typography } from '@mui/material';

const CustomCodeBlock = ({ language, code }) => {
    const lines = code.split('\n');
    const maxLineNumber = lines.length.toString().length;

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                backgroundColor: '#1e1e1e', 
                borderRadius: 1, 
                overflow: 'hidden' 
            }} 
            className="shadow-md"
        >
            {/* Line Numbers Box */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#2d2d2d',
                    color: '#888',
                    padding: '0.5em',
                    width: `${maxLineNumber * 1.5}em`, // Adjust width based on line numbers
                    textAlign: 'right',
                    fontFamily: 'monospace'
                }}
                className="shadow-sm"
            >
                {lines.map((_, index) => (
                    <Typography key={index} variant="body2" sx={{ color: '#888' }}>
                        {index + 1}
                    </Typography>
                ))}
            </Box>
            {/* Code Block */}
            <Box
                sx={{ 
                    flexGrow: 1,
                    padding: '0.5em'
                }}
            >
                <SyntaxHighlighter
                    language={language}
                    style={solarizedlight}
                    showLineNumbers={false}
                    customStyle={{ 
                        background: '#1e1e1e', 
                        padding: 0, 
                        margin: 0, 
                        fontFamily: 'monospace'
                    }}
                >
                    {code}
                </SyntaxHighlighter>
            </Box>
        </Box>
    );
};

export default CustomCodeBlock;

