import { Box, Grid } from "@mui/material";
import { dashboardCardData, imageDashboardCardData } from "./dummyData";
import CardComponent from "./Dashboard/CardComponent";
import CardComponentWithImage from "./Dashboard/CardComponentWithImage";
import HeaderSection from "./Dashboard/HeaderSection";
import useCardGrid from "../hooks/useCardGrid";
import CustomerSatisfactionScore from "./Dashboard/CustomerSatisfactionScore";
import NetPromoterScore from "./Dashboard/NetPromoterScore";
import ProgressBlock from "./Dashboard/ProgressBlock";
import NPXChart from "./Dashboard/NPXChart";
import NPXTradeComparison from "./Dashboard/NPXTradeComparison";
import { WordCloudCard } from "./utils/WordCloudCard";
import EmpathyCharts from "./charts/EmpathyCharts";

const Dashboard = ()=>{
    const firstCardRow = useCardGrid(dashboardCardData, CardComponent);
    const secondCardRow = useCardGrid(imageDashboardCardData, CardComponentWithImage);
    const customerSatisfactionValue = 7;
    const npsScore = 70; 
    const feedbackData = {
        detractors: 5,
        passives: 100,
        promoters: 270,
    };
    const words = [
        { text: 'love', value: 64 },
        { text: 'onboarding', value: 11 },
        { text: 'support', value: 16 },
        { text: 'extend', value: 17 },
        { text: 'plan', value: 17 },
        { text: 'process', value: 11 },
        { text: 'compared', value: 10 },
        { text: 'competitors', value: 9 },
        { text: 'better', value: 7 },
        { text: 'client', value: 13 },
        { text: 'integration', value: 9 },
        { text: 'alternative', value: 9 },
        { text: 'capability', value: 9 },
        { text: 'automation', value: 7 },
        { text: 'subscription', value: 7 },
        { text: 'platform', value: 10 },
    ]
    return(
        <>
            <Box 
                className="bg-white min-h-screen"
                sx={{
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                <Box className='container mx-auto px-4 sm:px-6 md:px-8'>
                    <HeaderSection />
                    {firstCardRow}
                    {secondCardRow}
                    <Grid container spacing={4} marginY={3}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <CustomerSatisfactionScore value={customerSatisfactionValue} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <NetPromoterScore score={npsScore} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <ProgressBlock 
                                detractors={feedbackData.detractors}
                                passives={feedbackData.passives}
                                promoters={feedbackData.promoters}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXChart />
                        </Grid>
                        <Grid item xs={12}>
                            <NPXTradeComparison />
                        </Grid>
                        <Grid container spacing={4} sx={{ mb: 2 }} my={4} justifyContent="space-around">
                            <Grid item xs={12} sm={6} md={4}>
                                <WordCloudCard title="Promoters" words={words} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <WordCloudCard title="Detractors" words={words} />
                            </Grid>
                        </Grid>
                        {/* empathy map not completed */}
                        {/* <Grid item xs={12}>
                            <EmpathyCharts />
                        </Grid> */}   
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Dashboard;