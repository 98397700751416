import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw';

const MarkdownContainer = ({ content }) => (
    <Box
        sx={{
            p: 3,
            border: theme=> `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            boxShadow: 1,
            overflowY: 'auto',
            maxWidth: '100%',
            wordBreak: 'break-word',
            margin: 2
        }}
    >
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
        >
            {content} 
        </ReactMarkdown>
    </Box>
);

export default MarkdownContainer;
