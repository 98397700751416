import { Box, Typography, Divider } from "@mui/material";

const APIRequestBlock = () => {
    return (
        <Box className="p-6 bg-gray-100 rounded-md shadow-lg mt-4">
            <Typography variant="h5" className="font-bold" sx={{mb: 4}}>
                Request
            </Typography>

            <Box className="flex items-center justify-between mb-4">
                <Typography variant="subtitle1" className="font-semibold">
                    Request Body: application/json
                </Typography>
            </Box>

            <Divider className="mb-4" />

            <Typography variant="h6" className="font-semibold mt-4 mb-2">
                Review List of Reviews
            </Typography>

            <Typography variant="body1" className="mb-4">
                The API allows you to fetch a list of reviews, where each review contains fields such as <code className="bg-gray-200 px-1 py-0.5 rounded">content</code>, <code className="bg-gray-200 px-1 py-0.5 rounded">comment</code>, <code className="bg-gray-200 px-1 py-0.5 rounded">date_time</code>, <code className="bg-gray-200 px-1 py-0.5 rounded">user_name</code>, and other relevant details. These fields provide structured information about each review, enabling you to analyze user feedback effectively.
            </Typography>
        </Box>
    );
};

export default APIRequestBlock;