import { useState } from 'react';
import { Typography, Box, Tooltip, IconButton, Snackbar, Alert } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { refreshAPISourceToken } from '../../../api/API';

const APIKeySection = ({ apiKey, handleCopy, handleRefresh, setApiKey }) => {
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(apiKey);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefreshAPIKey = async () => {
        setLoader(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const dashboard_id = localStorage.getItem('dashboardId');
            const response = await refreshAPISourceToken(dashboard_id);
            setApiKey(response.api_key);
        } catch (error) {
            console.log('error occurred while refresh ', error);
            setError('Failed to refresh');
        } finally {
            setLoader(false);
            setError(null);
        }
    };

    return (
        <>
            <Box className="flex items-center bg-white p-4 rounded-lg shadow-md min-w-0 mx-2">
                <Box className="flex-1 min-w-0">
                    <Typography variant="h6" className="text-gray-800">
                        API Key:
                    </Typography>
                    <Box className="flex items-center bg-white p-2 rounded-lg shadow-sm border border-gray-300 mt-1">
                        <Typography variant="body1" className="font-mono text-gray-800 truncate">
                            {apiKey}
                        </Typography>
                    </Box>
                </Box>
                <Box className="ml-4 flex-shrink-0">
                    <Tooltip title="Copy to clipboard">
                        <IconButton onClick={handleCopyClick} color="primary" aria-label="copy-api-key">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh API Key">
                        <IconButton 
                            onClick={handleRefreshAPIKey} 
                            color="primary" 
                            aria-label="refresh-api-key"
                            disabled={loader}  // Disable when loader is true
                        >
                            <RefreshIcon className={loader ? 'animate-spin' : ''} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="API Key copied to clipboard"
            >
                <Alert onClose={handleClose} severity="success">
                    API Key copied to clipboard
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            {error && (
                <Snackbar
                    open={Boolean(error)}
                    autoHideDuration={6000}
                    onClose={() => setError(null)}
                    message={error}
                >
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default APIKeySection;
