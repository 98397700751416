import { Box, Drawer, Typography, Divider } from '@mui/material';
import AppFormData from '../AppFormData';
import APIDataSource from './APIDataSource';

const DataSourceDrawer = ({ open, onClose, sourceName, sourceDescription, example }) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '85%',
                    boxSizing: 'border-box',
                    minHeight: '100vh' 
                },
            }}
        >
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', padding: 2,}}
            >
                <Typography variant="h6">Connect Feedback Source</Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: 2 }}>
                {sourceName==='API' ? (
                    <APIDataSource />
                ) : (
                    <AppFormData
                    sourceName={sourceName}
                    sourceDescription={sourceDescription}
                    example={example}
                    closeDrawer={onClose}
                    />
                )}
            </Box>
        </Drawer>
    );
};

export default DataSourceDrawer;

