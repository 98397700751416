import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const ReviewsLoader = ({ message }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap:3,
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '8px 20px',
        position: 'absolute',
        bottom: '105px', // Adjust this based on the exact position above "Data sources"
        zIndex: 10,
      }}
    >
      <CircularProgress color="primary" size={24} />
      <Typography variant="body2" sx={{ marginLeft: '8px', color: '#37517e' }}>
        {message}
      </Typography>
    </Box>
  )
};

export default ReviewsLoader;
