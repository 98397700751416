import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { checkDashboardName } from "../../api/API";

const FormPopup = ({
    openForm,
    handleFormClose,
    newWorkspace,
    handleInputChange,
    handleCreateWorkspace,
    isFormValid,
}) => {
    const [nameError, setNameError] = useState("");
    const [isNameValid, setIsNameValid] = useState(false);

    const checkDashboardNameExists = async (dashboard_name) => {
        try {
            const orgId = sessionStorage.getItem('orgId')
            const response = await checkDashboardName(dashboard_name, orgId);
            return response;
        } catch (error) {
            console.error("Error checking workspace name:", error);
            return false;
        }
    };

    const handleNameBlur = async () => {
        if (newWorkspace.workspaceName.trim() === "") {
            setNameError("");
            setIsNameValid(false);
            return;
        }

        const nameExists = await checkDashboardNameExists(newWorkspace.workspaceName);

        if (nameExists) {
            setNameError("Workspace name is already present.");
            setIsNameValid(false);
        } else {
            setNameError("");
            setIsNameValid(true);
        }
    };


    return (
        <Dialog open={openForm} onClose={handleFormClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Create New Workspace
                <IconButton
                    aria-label="close"
                    onClick={handleFormClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    name="workspaceName"
                    label="Workspace Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newWorkspace.workspaceName}
                    onChange={(e) => {
                        handleInputChange(e);
                        setIsNameValid(false); // Reset validation state when user types
                        setNameError(""); // Clear any error
                    }}
                    onBlur={handleNameBlur} // Trigger name check on blur
                    required
                    error={!!nameError}
                    helperText={nameError}
                    InputProps={{
                        endAdornment: isNameValid && newWorkspace.workspaceName.trim() !== "" && (
                            <InputAdornment position="end">
                                <CheckIcon color="success" />
                            </InputAdornment>
                        ),
                    }}
                />

                {isNameValid && (
                    <Typography variant="body2" color="success.main">
                        Workspace name is available!
                    </Typography>
                )}
                <TextField
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newWorkspace.description}
                    onChange={handleInputChange}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFormClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCreateWorkspace} color="primary" disabled={!isFormValid() || !!nameError}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormPopup;
