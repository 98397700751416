import { Grid, Box } from '@mui/material';
import Card from '../../theme/Card';
import { transformCardData } from '../../../helper/helper';

const CardGridSection = ({ cardData }) => {
    let convertedCardData = transformCardData(cardData);
    return(
        <Box className='w-full max-w-7xl mx-auto my-8 p-4'>
            <Grid container spacing={4} justifyContent="space-between">
                {convertedCardData.map((data, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Card {...data} />
                    </Grid>
                ))}
            </Grid>
            {/* <Box className="flex justify-between my-4">
                <FancyButton><FilterListIcon />Filter by source</FancyButton>
                <FancyButton>View all feedback</FancyButton>
            </Box> */}
        </Box>
    )
};

export default CardGridSection;