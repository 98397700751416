import Datepicker from "react-tailwindcss-datepicker";

const CustomDatePicker = ({ value, onChange, onApply }) => {

  const handleValueChange = (newValue) => {
    onChange(newValue);
    onApply()
  };

  return (
    <Datepicker
      placeholder="Select Date Range"
      showShortcuts={true}
      value={value}
      onChange={handleValueChange}
      showFooter={true}
    />
  );
};

export default CustomDatePicker;
