import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import BarCharts from './charts/BarCharts';
import { useFeedbackQContext } from '../context/FeedbackQ';
import FiltersSection from './theme/secondview/FilterSection';
import CardGridSection from './theme/secondview/CardGridSection';
import ThemeTableSection from './theme/secondview/ThemeTableSection';
import ThemeDrawer from './theme/secondview/ThemeDrawer';
import { formatTopThemes } from '../helper/helper';

const FeatureList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [themesData, setThemesData] = useState([]);
    const [themeAnalysisData, setThemeAnalysisData] = useState({});
    const [selectionRange, setSelectionRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection'
    });
    const [showCalendar, setShowCalendar] = useState(false);

    const {
        themes,
        loadingThemes,
        themeError,
        themeAnalysis,
        loadingAnalysis,
        analysisError,
        selectedItem,
        setSelectedItem
    } = useFeedbackQContext();

    useEffect(() => {
        if(!loadingThemes && !themeError){
            setThemesData(themes);
        }
    }, [themes,loadingThemes,themeError]);

    useEffect(() => {
        if (!loadingAnalysis && !analysisError) {
            setThemeAnalysisData(themeAnalysis);
        }
    }, [themeAnalysis, loadingAnalysis, analysisError]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleDateRangeChange = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const handleOpenDrawer = (item) => {
        setSelectedItem(item);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedItem(null);
    };

    const filteredData = themesData.filter(item => item?.type !== 'Spams');
    const totalCount = filteredData.length;
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box className="w-full max-w-7xl h-full text-black mx-auto p-4 sm:p-6 md:p-8 box-border mb-2 md:mb-6">
            <Box className="flex border border-gray-300 rounded-lg p-4 mb-4">
                <Box className="flex-1">
                    <Typography variant="h6" component="div" gutterBottom>
                        Themes
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Actionable insights clustered for each category: feature, bug, customer question, appreciation. The report is updated automatically with new data.
                    </Typography>
                </Box>
            </Box>
            <Box className="w-full p-5">
                {/* <Box className="flex items-center justify-between rounded-lg p-4 mb-4">
                    <FiltersSection 
                        showCalendar={showCalendar}
                        setShowCalendar={setShowCalendar}
                        selectionRange={selectionRange}
                        handleDateRangeChange={handleDateRangeChange}
                    />
                </Box> */}
                <CardGridSection cardData={themeAnalysisData.cardData} />
                { 
                    themeAnalysis.topThemes && themeAnalysis.topThemes.length > 0 ? (
                        <Box className='mt-8 p-6 bg-white rounded-lg shadow-md'>
                            <Typography variant='h4' sx={{padding: '20px'}}>Top 5 Themes</Typography>
                            <BarCharts data={formatTopThemes(themeAnalysis.topThemes)} title="Top 5 Themes" />
                        </Box>
                    ) : (
                        <Box className='mt-8 p-6 bg-white rounded-lg shadow-md'>
                            <Typography variant='h6' align='center'>
                                Top themes are not identified.
                            </Typography>
                        </Box>
                    )
                }
                <ThemeTableSection 
                    paginatedData={paginatedData}
                    handleOpenDrawer={handleOpenDrawer}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    totalCount={totalCount}
                />
                { selectedItem && (
                    <ThemeDrawer 
                        drawerOpen={drawerOpen}  
                        handleCloseDrawer={handleCloseDrawer} 
                        themeData={selectedItem} 
                    />
                )}
            </Box>
        </Box>
    );
};

export default FeatureList;
