import { useEffect } from "react";

const Footer = ()=>{
    useEffect(()=>{
        // load Google Analytics script
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Q1HJ31WW7V';
        script.async = true;
        document.body.appendChild(script);

        // initialize Google analytics
        const script2 = document.createElement('script');
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Q1HJ31WW7V');
        `
        document.body.appendChild(script2);
        
        return()=>{
            // Clean up scripts if the component unmounts (optional, for routing changes)
            document.body.removeChild(script);
            document.body.removeChild(script2);
        }
    })
    return(
        <footer>
            {/* Add any footer-specific content here if needed */}
        </footer>
    )
}

export default Footer;