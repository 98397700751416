import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    IconButton,
    Drawer,
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimerIcon from '@mui/icons-material/Timer';
import CodeIcon from '@mui/icons-material/Code';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RuleIcon from '@mui/icons-material/Rule';
import GroupIcon from '@mui/icons-material/Group';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import StorageIcon from '@mui/icons-material/Storage'; // New icon for API Access
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // New icon for File Upload
import CloseIcon from '@mui/icons-material/Close';
import {  getUsageData } from '../../api/API';

const AccountInfoCard = ({ title, value, icon }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Card
            elevation={3}
            sx={{
                backgroundColor: '#FAFAFA',
                color: '#4F4F4F',
                borderRadius: '15px',
                border: '1px solid #E0E0E0',
            }}
        >
            <CardContent>
                <Box display="flex" alignItems="center">
                    <Box mr={2} color="#4F4F4F">
                        {icon}
                    </Box>
                    <Box>
                        <Typography variant="h6" sx={{ opacity: 0.8 }}>
                            {title}
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {value}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    </Grid>
);

const AccountInfoDrawer = ({ drawerOpen, toggleDrawer }) => {
    const [accountData, setAccountData] = useState({
        accountType: '',
        daysLeft: '',
        openaiTokens: '',
        credits: '',
        datapoints: '',
        app_source: '',
        dashboards: '',
        customRules: '',
        seats: '',
        askqQueries: '',
        apiAccess: '',
        fileUpload: '',
    });
    const [accountLimit, setAccountLimit] = useState({
        max_daysLeft: '',
        max_openaiTokens: '',
        max_credits: '',
        max_datapoints: '',
        max_app_source: '',
        max_dashboards: '',
        max_customRules: '',
        max_seats: '',
        max_askqQueries: '',
        max_apiAccess: '',
        max_fileUpload: '',
    });

    useEffect(() => {
        const tokenDataGen = async () => {
            const orgId = sessionStorage.getItem('orgId');
            const response = await getUsageData();
            const tokenResponse = response;
            console.log("tokenResponse", tokenResponse);

            const setAccDetails = {
                accountType: tokenResponse.account_type,
                daysLeft: tokenResponse.days_left,
                openaiTokens: tokenResponse.tokens_used,
                credits: tokenResponse.credit_used,
                datapoints: tokenResponse.data_points_used,
                app_source: tokenResponse.app_count,
                dashboards: tokenResponse.dashboards_created,
                customRules: tokenResponse.custom_rules_used,
                seats: tokenResponse.seats_used,
                askqQueries: tokenResponse.ask_q_used,
                apiAccess: tokenResponse.api_access_used,
                fileUpload: tokenResponse.file_upload_used,
            };
            const maxSetAccDetails = {
                max_openaiTokens: tokenResponse.token_limit,
                max_credits: tokenResponse.credit_limit,
                max_datapoints: tokenResponse.data_points,
                max_app_source: tokenResponse.app_source,
                max_dashboards: tokenResponse.dashboards,
                max_customRules: tokenResponse.custom_rules,
                max_seats: tokenResponse.max_seats,
                max_askqQueries: tokenResponse.ask_q,
                max_apiAccess: tokenResponse.api_access,
                max_fileUpload: tokenResponse.file_upload,
            };
            setAccountData(setAccDetails);
            setAccountLimit(maxSetAccDetails);
        };
        tokenDataGen();
    }, [drawerOpen]);

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
                sx: {
                    width: '70%',
                    backgroundColor: '#F7F7F7',
                    color: '#4F4F4F',
                },
            }}
        >
            <Box
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    backgroundColor: '#EFEFEF',
                    borderBottom: '1px solid #D0D0D0',
                }}
            >
                <Typography variant="h6">Account Information</Typography>
                <IconButton onClick={toggleDrawer(false)} sx={{ color: '#4F4F4F' }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box p={3} height="100%">
                <Grid container spacing={3}>
                    <AccountInfoCard
                        title="Account Type"
                        value={accountData.accountType.toUpperCase()}
                        icon={<AccountBalanceIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="Days Left"
                        value={`${accountData.daysLeft}`}
                        icon={<TimerIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="OpenAI Tokens"
                        value={`${parseInt(accountData.openaiTokens)}/${parseInt(accountLimit.max_openaiTokens)}`}
                        icon={<CodeIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="Credits"
                        value={`${Math.floor(accountData.credits)}/${Math.floor(accountLimit.max_credits)}`}
                        icon={<CreditCardIcon fontSize="large" />}
                    />

                    <AccountInfoCard
                        title="Datapoints"
                        value={`${accountData.datapoints}/${accountLimit.max_datapoints}`}
                        icon={<BarChartIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="App Source"
                        value={`${accountData.app_source}/${accountLimit.max_app_source}`}
                        icon={<DashboardIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="Dashboards"
                        value={`${accountData.dashboards}/${accountLimit.max_dashboards}`}
                        icon={<DashboardIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="Custom Rules"
                        value={`${accountData.customRules}/${accountLimit.max_customRules}`}
                        icon={<RuleIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="Seats"
                        value={`${accountData.seats}/${accountLimit.max_seats}`}
                        icon={<GroupIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="AskQ Queries"
                        value={`${accountData.askqQueries}/${accountLimit.max_askqQueries}`}
                        icon={<QueryBuilderIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="API Access"
                        value={`${accountData.apiAccess}/${accountLimit.max_apiAccess}`}
                        icon={<StorageIcon fontSize="large" />}
                    />
                    <AccountInfoCard
                        title="File Upload"
                        value={`${accountData.fileUpload}/${accountLimit.max_fileUpload}`}
                        icon={<CloudUploadIcon fontSize="large" />}
                    />
                </Grid>
            </Box>
        </Drawer>
    );
};

export default AccountInfoDrawer;
