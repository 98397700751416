// Example payload to be used in API documentation
export const examplePayload = `
{
    "content": "Great product!",
    "date_time": "2024-08-28 12:00:00",
    "user_name": "John Doe",
    "rating": 5
}
`;

// Code examples for various programming languages
export const codeExamples = {
    curl: `
curl -X POST "your_api_endpoint_here" \\
-H "Content-Type: application/json" \\
-H "api_key: your_api_key_here" \\
-d '[
    {
        "content": "This is the content of the first review.",
        "date_time": "2024-08-28 12:00:00",
        "user_name": "User1",
        "rating": 5
    },
    {
        "content": "This is the content of the second review.",
        "date_time": "2024-08-28 13:00:00",
        "user_name": "User2",
        "rating": 4
    },
    {
        "content": "This is the content of the third review.",
        "date_time": "2024-08-28 14:00:00",
        "user_name": "User3",
        "rating": 3
    }
]'
    `,
    python: `
import requests

# Define the API endpoint
url = "your_api_endpoint_here"

# Define the headers, including the API key
headers = {
    'Content-Type': 'application/json',
    'api_key': 'your_api_key_here'
}

# Define the JSON payload
payload = [
    {
        "content": "This is the content of the first review.",
        "comment": "First review comment",
        "date_time": "2024-08-28T12:00:00Z",
        "user_name": "User1",
        "rating": 5
    },
    {
        "content": "This is the content of the second review.",
        "comment": "Second review comment",
        "date_time": "2024-08-28T13:00:00Z",
        "user_name": "User2",
        "rating": 4
    },
    {
        "content": "This is the content of the third review.",
        "comment": "Third review comment",
        "date_time": "2024-08-28T14:00:00Z",
        "user_name": "User3",
        "rating": 3
    }
]

# Make the POST request
response = requests.post(url, headers=headers, json=payload)

# Print the response from the server
print(response.status_code)
print(response.json())
    `,
    nodejs: `
const axios = require('axios');

const url = "your_api_endpoint_here";

const headers = {
    "Content-Type": "application/json",
    "api_key": "your_api_key_here"
};

const data = [
    {
        "content": "This is the content of the first review.",
        "comment": "First review comment",
        "date_time": "2024-08-28T12:00:00Z",
        "user_name": "User1",
        "rating": 5
    },
    {
        "content": "This is the content of the second review.",
        "comment": "Second review comment",
        "date_time": "2024-08-28T13:00:00Z",
        "user_name": "User2",
        "rating": 4
    },
    {
        "content": "This is the content of the third review.",
        "comment": "Third review comment",
        "date_time": "2024-08-28T14:00:00Z",
        "user_name": "User3",
        "rating": 3
    }
];

axios.post(url, data, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));
    `,
    php: `
<?php
$apiUrl = "your_api_endpoint_here";
$apiKey = "your_api_key_here";

// Prepare the payload as an array
$payload = [
    [
        "content" => "This is the content of the first review.",
        "comment" => "First review comment",
        "date_time" => "2024-08-28T12:00:00Z",
        "user_name" => "User1",
        "rating" => 5
    ],
    [
        "content" => "This is the content of the second review.",
        "comment" => "Second review comment",
        "date_time" => "2024-08-28T13:00:00Z",
        "user_name" => "User2",
        "rating" => 4
    ],
    [
        "content" => "This is the content of the third review.",
        "comment" => "Third review comment",
        "date_time" => "2024-08-28T14:00:00Z",
        "user_name" => "User3",
        "rating" => 3
    ]
];

// Convert the payload array to JSON
$jsonPayload = json_encode($payload);

// Initialize cURL session
$ch = curl_init($apiUrl);

// Set the cURL options
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    'Content-Type: application/json',
    'api_key: ' . $apiKey
]);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, $jsonPayload);

// Execute the cURL request and get the response
$response = curl_exec($ch);
$httpStatus = curl_getinfo($ch, CURLINFO_HTTP_CODE);

// Close the cURL session
curl_close($ch);

// Output the response
if ($httpStatus == 200) {
    echo "Success: " . $response;
} else {
    echo "Error: " . $response;
}
?>
    `,
};


// Explanations for each code example
export const codeExplanations = {
    curl: `
**API URL and API Key:**

- Replace \`your_api_endpoint_here\` with the actual server URL you are using.
- Replace \`your_api_key_here\` with your actual API key.

**Payload:**

- The payload contains an array of JSON objects, each representing a review with fields like \`content\`, \`comment\`, \`date_time\`, \`user_name\`, \`rating\`, \`source\`, \`type\`, \`rca\`, \`emotional_analysis\`, \`sentimental_analysis\`, and \`theme\`.

**cURL Options:**

- \`-X POST\` specifies the HTTP method to be used, which is POST.
- \`-H\` options define headers, such as content type and API key.
- \`-d\` is used to send the JSON payload with the POST request.

**Executing the Request:**

- Execute this command in a terminal to send the HTTP POST request.
    `,
    python: `
**API URL and API Key:**

- The \`url\` variable stores the API endpoint URL.
- Replace \`your_api_key_here\` with your actual API key in the \`headers\` dictionary.

**Payload:**

- The payload is a list of dictionaries, each representing a review object with fields like \`content\`, \`comment\`, \`date_time\`, \`user_name\`, \`rating\`, etc.

**Sending the Request:**

- The \`requests.post()\` function sends the POST request with the specified URL, headers, and JSON payload.
- The server's response is printed using \`print()\`.
    `,
    nodejs: `
**API URL and API Key:**

- \`url\` stores the API endpoint.
- Replace \`your_api_key_here\` with your actual API key in the \`headers\` object.

**Payload:**

- \`data\` is an array of objects, each representing a review with fields like \`content\`, \`comment\`, \`date_time\`, \`user_name\`, \`rating\`, and others.

**Sending the Request:**

- \`axios.post()\` sends a POST request with the URL, payload, and headers.
- The promise handles the response or error using \`.then()\` and \`.catch()\` blocks.
    `,
    php: `
**API URL and API Key:**

- Replace \`your_api_endpoint_here\` with your actual server URL.
- Replace \`your_api_key_here\` with your actual API key.

**Payload:**

- The payload is an array of associative arrays, each representing a review object with fields like \`content\`, \`comment\`, \`date_time\`, \`user_name\`, etc.

**cURL Options:**

- \`CURLOPT_RETURNTRANSFER\` is set to \`true\` to return the server's response as a string.
- \`CURLOPT_HTTPHEADER\` sets the headers, including content type and API key.
- \`CURLOPT_POST\` and \`CURLOPT_POSTFIELDS\` are used to specify the POST request method and pass the JSON-encoded payload.

**Executing the Request:**

- The response from \`curl_exec($ch)\` is checked for success using HTTP status codes.
- Output is printed based on whether the request was successful or failed.
    `,
};

export const apiResponseData = [
    {
        statusCode: 201,
        label: "Created",
        color: "success",
        description: "Returned if the request is successful.",
    },
    {
        statusCode: 400,
        label: "Bad Request",
        color: "warning",
        description: "Returned when the request contains incorrect or malformed data.",
    },
    {
        statusCode: 401,
        label: "Unauthorized",
        color: "error",
        description: "Returned when the API key is missing or invalid.",
    },
];

