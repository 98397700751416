import { Card, CardContent, Typography } from "@mui/material";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);
export const WordCloudCard = ({ title, words }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <WordCloud 
          data={words}  
          width={500}
          height={300}
          font="Times"
          fontStyle="italic"
          fontWeight="bold"
          fontSize={(word) => Math.log2(word.value) * 5}
          spiral="rectangular"
          rotate={(word) => word.value % 360}
          padding={5}
          random={Math.random}
          fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
        />
      </CardContent>
    </Card>
)