// Idvalidator.js
// import axios from 'axios';

// const api = axios.create({
//     baseURL: 'https://feedbackq-service.insightq.ai',
//     headers: {
//         'Content-Type': 'application/json'
//     },
//     withCredentials: true
// })

// // Add a request interceptor to include the auth token in each request
// api.interceptors.request.use(
//     config => {
//         const token = sessionStorage.getItem('auth_token');
//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

export const validateAppStoreId = async (appId) => {
    // const url = `https://itunes.apple.com/lookup?id=${appId}`;
    // try {
    //     const response = await fetch(url);
    //     const data = await response.json();
    //     return data.resultCount > 0;
    // } catch (error) {
    //     console.error('Error validating App Store ID:', error);
    //     return false;
    // }
    return true;
};


// export const validatePlayStoreId = async (appId) => {
//     const url = `http://127.0.0.1:7070/validate/validate_play_store_id/${appId}`;
//     try {
//         const response = await fetch(url);
//         const result = await response.json();
//         return result.valid;
//     } catch (error) {
//         console.error('Error validating Play Store ID:', error);
//         return false;
//     }
// };
