import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import SkeletonSection from '../../utils/shimmerUI/SkeletonSection';

const ListSection = ({ title, items=[], loading }) => {
    return (
        <Box className='flex-1 border border-gray-300 rounded-lg shadow-md min-h-[300px] max-h-[500px] overflow-y-auto'>
            {loading ? (
                <SkeletonSection loading={loading} />
            ) : (
                <>
                    <Typography variant='h5' className='p-4 border-gray-300 text-center'>
                        {title}
                    </Typography>
                    <Box className='p-4'>
                        <List>
                            {items.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`• ${item}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ListSection;