import React, { useState } from "react";
import { Box, Tabs, Tab, Snackbar, IconButton, Tooltip } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { codeExamples, codeExplanations } from "../../../helper/staticContent";
import { renderExplanation, replaceTemplateValues } from "../../../helper/helper";
import CustomCodeBlock from "../../utils/CustomCodeBlock";

const APIUsageBlock = ({ apiKey }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const languageMapping = {
        curl: 'bash',
        nodejs: 'javascript'
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Prepare code example and explanation with API Key substitution
    const currentKey = Object.keys(codeExamples)[tabIndex];
    const currentCodeExample = replaceTemplateValues(
        codeExamples[Object.keys(codeExamples)[tabIndex]],
        { apiKey }
    );

    const currentLanguage = languageMapping[currentKey] || currentKey;

    const currentExplanation = codeExplanations[Object.keys(codeExplanations)[tabIndex]];

    // Copy code to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(currentCodeExample)
            .then(() => setSnackbarOpen(true))
            .catch(err => console.error('Failed to copy: ', err));
    };

    return (
        <Box className="p-4 rounded-md shadow-lg mt-3 bg-slate-100">
            {/* Tabs for Code Examples */}
            <Box className="bg-white rounded-md relative">
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="code-example-tabs">
                    <Tab label="cURL" />
                    <Tab label="Python" />
                    <Tab label="Node.js" />
                    <Tab label="PHP" />
                </Tabs>

                {/* Copy Icon */}
                <Tooltip title="Copy to clipboard">
                    <IconButton
                        onClick={handleCopy}
                        aria-label="copy-code"
                        sx={{ position: 'absolute', top: 16, right: 16, color: "black" }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>

                {/* Code Snippet Display */}
                <Box className="p-4">
                    <CustomCodeBlock
                        language={currentLanguage}
                        code={currentCodeExample}
                    />

                    {/* Explanation Section */}
                    <Box className="mt-4">
                        {renderExplanation(currentExplanation)}
                    </Box>
                </Box>
            </Box>

            {/* Snackbar for Copy Confirmation */}
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard!
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default APIUsageBlock;

