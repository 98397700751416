const HeaderSection = ()=>{
    return(
        <>
            <div className="text-start p-4">
                <h1 className="text-2xl font-bold text-blue-900">Welcome to the Dashboard,</h1>
                <p className="mt-4 text-gray-600">
                    This is your main area to see important metrics and activities.
                    Here, you can monitor real-time performance, review detailed statistics, 
                    and track ongoing tasks or projects. Stay informed and make data-driven decisions 
                    to keep your operations running smoothly.
                </p>
            </div>
        </>
    )
}

export default HeaderSection;