import Chart from "react-google-charts";

const PieCharts = ({ data }) => {
    const options = {
        pieSliceText: "label", 
        pieStartAngle: 180,
        tooltip: { trigger: "hover" },
        slices: {
            0: { color: "#a5d6a7" },
            1: { color: "#fff59d" },
            2: { color: "#ef9a9a" }, 
        },
        chartArea: { width: "100%", height: "100%" },
        legend: { position: "right", alignment: "center" },  
        pieSliceBorderColor: 'transparent',
        pieHole: 0, 
        slices: {
            0: { offset: 0 }, 
            1: { offset: 0 },
            2: { offset: 0 },
        },
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"200px"}
            />
        </div>
    );
};

export default PieCharts;
