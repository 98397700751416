import { Box, Chip, Typography } from "@mui/material";

const APIDescription = () => {
    return (
        <Box sx={{ px: 4, py: 2 }}>
            <Typography
                variant="h6"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Chip
                    label="POST"
                    color="success"
                    variant="outlined"
                    sx={{ mr: 2 }}
                />
                API for Handling Reviews
            </Typography>

            <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
                This API is designed to process reviews sent from various
                sources and associate them with a specific dashboard in your
                system based on an <strong>api_key</strong>. The system
                validates the payload, ensuring that the data is correctly
                structured and adheres to predefined rules before processing.
            </Typography>

            <Typography variant="body1" sx={{ mb: 2, fontWeight: "bold" }}>
                Key Features:
            </Typography>

            <ul style={{ paddingLeft: "24px", marginBottom: "1rem" }}>
                <li style={{ marginBottom: "8px" }}>
                    <Typography
                        variant="body1"
                        sx={{ lineHeight: 1.6 }}
                        component="span"
                    >
                        <strong>API Key Authentication:</strong> Each request
                        must include a valid API key in the header. This API key
                        is used to identify the associated dashboard.
                    </Typography>
                </li>
                <li style={{ marginBottom: "8px" }}>
                    <Typography
                        variant="body1"
                        sx={{ lineHeight: 1.6 }}
                        component="span"
                    >
                        <strong>Payload Validation:</strong> The API validates
                        the structure of the reviews sent in the request body to
                        ensure that each review contains the necessary fields
                        (content, date_time, user_name, rating, source, type,
                        theme), and that the values for rating, source, and
                        theme meet specific criteria.
                    </Typography>
                </li>
                <li style={{ marginBottom: "8px" }}>
                    <Typography
                        variant="body1"
                        sx={{ lineHeight: 1.6 }}
                        component="span"
                    >
                        <strong>Error Handling:</strong> The API returns
                        meaningful error messages for missing or invalid fields,
                        making it easier to debug issues with the payload.
                    </Typography>
                </li>
                <li style={{ marginBottom: "8px" }}>
                    <Typography
                        variant="body1"
                        sx={{ lineHeight: 1.6 }}
                        component="span"
                    >
                        <strong>Success Response:</strong> Upon successful
                        validation, the API returns a success message and the
                        name of the dashboard that was identified by the{" "}
                        <strong>api_key</strong>.
                    </Typography>
                </li>
            </ul>
        </Box>
    );
};

export default APIDescription;


