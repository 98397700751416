import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

export const getSentimentDetails = (sentiment) => {
    if(sentiment == null)return []
    switch (sentiment) {
        case 'Positive':
            return { icon: <SentimentSatisfiedAltIcon color="success" />, color: 'success' };
        case 'Negative':
            return { icon: <SentimentVeryDissatisfiedIcon color="error" />, color: 'error' };
        case 'Neutral':
            return { icon: <SentimentNeutralIcon color="action" />, color: 'action' };
        default:
            return { icon: <SentimentNeutralIcon color="action" />, color: 'action' };
    }
};