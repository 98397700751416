import { Box, TableContainer, Table, TableBody, TableRow, TableCell, 
    IconButton, TablePagination, Avatar, Typography
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import featureIcon from '../../../assets/icons/gear.png';
import bugIcon from '../../../assets/icons/bugs.png';

const ThemeTableSection = ({ paginatedData, handleOpenDrawer, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, totalCount }) => (
    <Box className="bg-white border border-gray-300 rounded-lg p-6 mt-8">
        <TableContainer>
            <Table>
                <TableBody>
                    {paginatedData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={5} align="center">
                                <Box className="flex flex-col items-center">
                                    <Typography 
                                        variant="body1" 
                                        color="textSecondary" 
                                        className="animate-fade-in-out"
                                        paddingY={4}
                                    >
                                        No themes available
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        paginatedData.map((item, index) => (
                            <TableRow
                                key={index}
                                hover
                                onClick={() => handleOpenDrawer(item)}
                                sx={{ padding: 1.5}}
                            >   
                                <TableCell sx={{ paddingX: 1.5, width: '5%' }}>
                                    <Avatar alt='Theme type icons' src={item.type === 'Bugs'? bugIcon : featureIcon } sx={{ width: 24, height: 24 }} />
                                </TableCell>
                                <TableCell className='truncate'>{item.title}</TableCell>
                                <TableCell>{item.reviews_count}</TableCell>
                                <TableCell>{item.reviews_percentage + '%'}</TableCell>
                                <TableCell align="right">
                                    <IconButton edge="end" aria-label="more" size="small">
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <Box className="flex justify-center mt-4">
            { paginatedData.length !== 0 && (
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    variant="outlined"
                    component='div'
                />
            )}
        </Box>
    </Box>
);

export default ThemeTableSection;
