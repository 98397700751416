import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box } from '@mui/material';

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    let BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      BASE_URL = 'https://insightq.ai';
    }
    window.location.href = `${BASE_URL}/reset-password`
  }
  return (
    <>
      <IconButton sx={{ color: 'black', marginLeft: '5px' }} onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ borderRadius: "50%" }}
      >
        <MenuItem onClick={handleClose} >
          <Box onClick={handleReset}>
            <LockResetIcon sx={{ marginRight: '10px' }} />
            Reset Password
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SettingsMenu;

