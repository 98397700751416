import { Typography, Box, Chip, Tooltip, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { examplePayload } from "../../../helper/staticContent";
import { useState } from "react";
import URLBlock from "./URLBlock";
import APIKeySection from "./APIKeySection";
import CustomCodeBlock from "../../utils/CustomCodeBlock";

const SDKUsageDescription = ({apiKey, apiUrl, setApiKey }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopy = () => {
        const textToCopy = examplePayload
            .split("\n")
            .map((line) => line.replace(/^\d+\. /, "").trim())
            .join("\n");
        navigator.clipboard.writeText(textToCopy);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleRefresh = () => {
        // Mock API Key refresh logic // Update with actual refresh logic if needed
    };

    return (
        <Box className="bg-white shadow-lg rounded-lg" sx={{ px: 4, py: 2 }}>
            <Typography variant="h5" className="font-semibold text-gray-800" sx={{display: 'flex', alignItems: 'center', mb: 4}}>
                How to Use the SDK Code:
            </Typography>

            <ul style={{ paddingLeft: '24px', marginBottom: '1rem'}}>
                <li style={{ marginBottom: '8px'}}>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }} component='span' className="mb-4 text-gray-700">
                        <strong>API Setup:</strong> First, set up the API by running the app on a server. Ensure that Database is properly configured and running, and that your dashboard collection contains documents with the api_key.
                    </Typography>
                </li>
                <li style={{ marginBottom: '8px'}}>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }} component='span' className="mb-4 text-gray-700">
                        <strong>Making API Requests:</strong> You can interact with this API using an SDK or directly from your application. Here's an example of how to use the API with the SDK (or using a direct HTTP request).
                    </Typography>
                </li>
                <li style={{ marginBottom: '8px'}}>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }} component='span' className="mb-4 text-gray-700">
                        <strong>Request Structure:</strong>
                    </Typography>
                </li>
                <Box className="mb-4">
                    <ul className="list-disc pl-6 space-y-2">
                        <li>
                            <strong>URL:</strong> <code className="text-gray-600">/api/v1/reviews</code>
                        </li>
                        <li>
                            <strong>Method:</strong> <Chip label='POST' color="success" variant="outlined" />
                        </li>
                        <li>
                            <strong>Headers:</strong>
                            <ul className="list-disc pl-6 space-y-1">
                                <li><code className="text-gray-600">api_key</code>: Your unique API key (sent as part of the request headers).</li>
                                <li><code className="text-gray-600">Content-Type</code>: application/json</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Payload:</strong> The payload should be raw JSON containing an array of review objects. Each review object must include:
                            <ul className="list-disc pl-6 space-y-1">
                                <li><code className="text-gray-600">content</code>: The review's content (text).</li>
                                <li><code className="text-gray-600">date_time</code>: The timestamp of when the review was created (ISO 8601 format).</li>
                                <li><code className="text-gray-600">user_name</code>: The name of the user who submitted the review.</li>
                                <li><code className="text-gray-600">rating</code>: A rating between 1 and 5 (integer).</li>
                            </ul>
                        </li>
                    </ul>
                </Box>
            </ul>

            {/* URL and API Key Section */}
            <Box flexDirection={{ xs: 'column', sm: 'row' }} className="mb-4 flex p-4">
                <Box className="flex-1 min-w-0">
                    <URLBlock url={apiUrl || 'https://feedbackq-service.insightq.ai/api/v1/reviews'} />
                </Box>
                <Box className="flex-1 min-w-0">
                    <APIKeySection apiKey={apiKey} handleCopy={handleCopy} handleRefresh={handleRefresh} setApiKey={setApiKey} />
                </Box>
            </Box>

            <Typography variant="body1" className="mb-4 text-gray-700" sx={{mb: 4, fontWeight: 'bold'}}>
                <strong>Example Payload:</strong>
            </Typography>

            <Box
                className="relative bg-white text-gray-800 p-4 rounded-lg border border-gray-300"
                sx={{ position: 'relative', padding: '1em', borderRadius: '8px', border: '1px solid #d1d5db' }}
            >
                <Tooltip title="Copy to clipboard">
                    <IconButton
                        className="absolute top-2 right-2"
                        onClick={handleCopy}
                        aria-label="copy-payload"
                        sx={{
                            position: 'absolute',
                            top: '8px',          
                            right: '8px',  
                            color: "black"  
                        }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
                <Box sx={{ marginTop: '2em' }}>
                    <CustomCodeBlock language='json' code={examplePayload} />
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message="Payload copied to clipboard!"
            />
        </Box>
    );
}

export default SDKUsageDescription;
