import { Box, Button, List, styled, ListItem, Typography, InputBase } from "@mui/material";

export const StyledList = styled(List)(({ theme })=> ({
    width: '100%',
    backgroundColor: 'white',
    flexGrow: 1,
    overflowY: 'auto',
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderBottom: '1px solid #333',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
}));

export const ListItemContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    width: '100%'
});

export const TextBox = styled(Box)({
    flex: 1
});

export const CountBox = styled(Box)({
    minWidth: '50px',
    textAlign: 'right',
    paddingRight: '8px',
});

export const PercentageBox = styled(Box)({
    minWidth: '50px',
    textAlign: 'right',
    paddingRight: '8px',
    color: '#2979ff',
});

export const FancyButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#3f51b5',
    color: 'white',
    fontSize: '10px',
    padding: '10px 20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#303f9f',
        transform: 'scale(1.05)',
    },
}));

export const CircleContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
}));

export const ClickableItem = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export const BlockContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    maxWidth: "100%", // Allow it to use full width
    margin: "0 auto", // Centering horizontally
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
}));

export const BlockTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.text.primary,
}));

export const ActionArea = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "right", 
    marginTop: theme.spacing(2),
    width: "100%",
}));

export const HoverStyledBTN = styled(Button)(({ theme }) => ({
    zIndex: 1,
    overflow: 'hidden',
    background: 'transparent',
    position: 'relative',
    padding: '8px 50px',
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '1em',
    letterSpacing: '2px',
    transition: '0.2s ease',
    fontWeight: 'bold',
    margin: '5px 0px',
    border: `4px solid ${theme.palette.success.main}`,
    color: theme.palette.primary.main,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '0%',
      height: '100%',
      background: theme.palette.success.main,
      zIndex: -1,
      transition: '0.2s ease',
    },
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.success.main,
      transition: '0.2s ease',
      '&:before': {
        width: '100%',
      },
    },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: "black",
  }));
  
export const DrawerFooter = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    // position: "absolute",
    position: "relative",
    bottom: 0,
    width: "100%",
}));
  
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    padding: theme.spacing(0, 1, 0, 1),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    width: "100%",
    backgroundColor: "white",
    border: "solid 1px black",
    borderRadius: theme.shape.borderRadius,
}));

export const activeLinkStyle = {
    backgroundColor: "#f0f0f0",
    color: "#37517e",
    borderLeft: "4px solid #37517e",
};
  