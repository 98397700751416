import React, { createContext, useContext, useState } from 'react';
import NotificationAlert from '../components/NotificationAlert';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState({
        open: false,
        status: '',
        text: '',
    });

    const showNotification = (status, text) => {
        setNotification({
            open: true,
            status: status === 'success' ? 'success' : 'error', // MUI's severity levels
            text,
        });
    };

    const handleClose = () => {
        setNotification((prev) => ({ ...prev, open: false }));
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <NotificationAlert
                status={notification.status}
                text={notification.text}
                open={notification.open}
                handleClose={handleClose}
            />
        </NotificationContext.Provider>
    );
};
