import { Box, CircularProgress, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { formatAskQResponse } from '../../helper/helper';

const ResponseContainer = ({ responses = [], loading }) => {
  const formattedResponse = formatAskQResponse(responses);

  if(loading){
    console.log('is is loading ',loading);
    <Box className="flex justify-center items-center h-32">
        <CircularProgress />
      </Box>
  }
  if (!Array.isArray(responses)) {
    return (
      <>
        { loading ? (
          <Typography>
            <CircularProgress />
          </Typography>
        ): (
          <Box className="mt-4 p-4 bg-white shadow-md rounded-lg">
            <Typography variant="h6" gutterBottom>
              AskQ says
            </Typography>
            <Typography>Error: Invalid response data.</Typography>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      { loading && responses.length === 0 ? (
        <Typography>
          <CircularProgress />
        </Typography>
      ) : (
        <Box className="mt-4 p-4 bg-white shadow-md rounded-lg">
          <Typography variant="h6" gutterBottom>
            AskQ says
          </Typography>
          <Box className="shadow-sm">
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {formattedResponse}
              </ReactMarkdown>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ResponseContainer;
