import { Box, Skeleton } from "@mui/material"

const SkeletonHeader = ()=>{
    return(
        <Box className='container mx-auto my-6 p-4'>
            <Skeleton variant="text" width={210} height={40} className='mb-4' />
            <Skeleton variant="rectangular" width="100%" height={118} className='mb-4' />
            <Skeleton variant="text" width={210} height={40} />
        </Box>
    )
}

export default SkeletonHeader;