import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import ManInSpace from '../svg/ManInSpace';
import { HoverStyledBTN } from '../../muitheme/StyledComponents';

const CustomError = () => {
  const error = useRouteError();
  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        fontFamily: 'Nunito Sans',
        color: '#0e0620',
      }}
    >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <ManInSpace />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" >
              UH OH! You're lost.
            </Typography>
            <Typography variant="h5" >
              {error.status}: {error.statusText || 'Something went wrong'}
            </Typography>
            <Typography variant="body1" sx={{ color: '#9e9e9e', mb: 4 }}>
              We're sorry, but the page you're looking for doesn't exist or an
              unexpected error has occurred.
            </Typography>
            <HoverStyledBTN onClick={() => (window.location.href = '/')}>
              Go Back Home
            </HoverStyledBTN>
          </Grid>
        </Grid>
    </Container>
  );
};

export default CustomError;
