import React from 'react';
import { Card, CardContent, Typography, Box } from "@mui/material";
import ProgressBar from './ProgressBar';

const ProgressBlock = ({ detractors, passives, promoters }) => {
  const total = detractors + passives + promoters;
  const detractorPercent = total ? detractors / total : 0;
  const passivePercent = total ? passives / total : 0;
  const promoterPercent = total ? promoters / total : 0;

  return (
    <Card sx={{ borderRadius: 2, padding: 2, height: 371 }}>
      <CardContent>
        <Typography variant="h6" className="font-bold text-gray-700 text-center">
          Feedback Distribution
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Detractors
          </Typography>
          <ProgressBar value={detractorPercent} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${detractors.toLocaleString()}`}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Passives
          </Typography>
          <ProgressBar value={passivePercent} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${passives.toLocaleString()}`}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" className="font-bold text-gray-700">
            Promoters
          </Typography>
          <ProgressBar value={promoterPercent} />
          <Typography variant="body2" className="text-center mt-1">
            {`+${promoters.toLocaleString()}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgressBlock;
