import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, Snackbar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomRule from "./rules/CustomRule"; 
import AddIcon from '@mui/icons-material/Add';
import DefaultTitles from "./rules/DefaultTitles";
import { getCustomTemplate, showbookmark } from "../helper/helper";
import RuleTemplateDrawer from "./rules/RuleTemplateDrawer"; 
import {custom_rules_bookmark,al_user_rules, remove_user_rules} from "../api/API"
import { useFeedbackQContext } from "../context/FeedbackQ";

const Rules = () => {
  const [selectedRule, setSelectedRule] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [customRules, setCustomRules] = useState([]); 
  const [customTemplateRules, setCustomTemplateRules] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTemplateID,setSelectedTemplateID] = useState(null);
  const [isEditTemplateRule, setisEditTemplateRule]= useState(false); 
  // const [custom_rules_bookmark,setCustom_rules_bookmark] = useState([]);
  const { rulebookmark, setRulebookmark, dashboardCheck } = useFeedbackQContext() ;
  const navigate = useNavigate();
  const customTemplate = useMemo(()=> getCustomTemplate(), [])
  const dashboardId = localStorage.getItem('dashboardId')
  // Simulate data fetching
  useEffect(() => {
    const userrules = async() =>{ 
      try {
        // Simulate a delay to show loading state
        const data = await al_user_rules(dashboardId) ;
       
          setCustomRules(data);
         // Simulate 1-second delay
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    

    userrules();
  }, [rulebookmark, dashboardCheck]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const data = await custom_rules_bookmark(dashboardId); // Await the promise
        console.log(data); // Log the fetched data

        // Assuming data is the list of templates, and you have savedIds defined
        const updatedTemplates = showbookmark(customTemplate, data);
        setCustomTemplateRules(updatedTemplates);
      } catch (error) {
        console.error(error.message); // Handle any errors
      }
    };
    
    fetchData(); // Call the async function
    setRulebookmark("");
  }, [customTemplate, rulebookmark, dashboardCheck]); // Depend on savedIds as well to update when savedIds change

  const handleRedirect = () => {
    navigate('/rules/create-new');
  };

  const handleEditClick = (rule) => {
    navigate(`/rules/edit/${rule.id}`);
  };

  const handleRemoveClick = (rule) => {
    setSelectedRule(rule);
    setShowConfirm(true);
  };

  const handleConfirmRemove = async() => {
    setCustomRules(prev => prev.filter(rule => rule.id !== selectedRule.id));
    const data = await remove_user_rules(selectedRule.id) ;
    setShowConfirm(false);
    setSelectedRule(null);
  };

  const handleCancelRemove = () => {
    setShowConfirm(false);
    setSelectedRule(null);
  };

  const handleSaveToggle = (rule) => {
    setCustomTemplateRules(prevRules => 
      prevRules.map(r => r.id === rule.id ? { ...r, save: !r.save } : r)
    );
  };

  const handleDrawerOpen = (id, isEdit) => {
    setSelectedTemplateID(id);
    setOpenDrawer(true);
    setisEditTemplateRule(isEdit);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleEditTemplateRule = (rule, isEdit)=>{
    handleDrawerOpen(rule, isEdit);
  }

  return (
    <div className="w-full max-w-7xl h-full text-black mx-auto p-4 sm:p-6 md:p-8 box-border mb-2 md:mb-6">
      <Box className="container mx-auto p-4">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h5" className="font-bold">
            Default Rules
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirect}
            sx={{ fontSize: '0.875rem' }} 
          >
            Create New Rule
          </Button>
        </Box>
        <DefaultTitles />
        {(customRules.length > 0 || customTemplate.length > 0) && (
          <>
            <Box 
              display="flex" 
              alignItems="center" 
              justifyContent="space-between" 
              mb={2} 
              mt={3}
              sx={{ 
                py: 1, 
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              <Typography variant="h5" gutterBottom>
                Custom Rules
              </Typography>
              <IconButton
                color="primary"
                onClick={handleRedirect}
                sx={{
                  borderRadius: '50%',
                  transition: 'background-color 0.3s, transform 0.3s',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.1)',
                    borderColor: 'blue'
                  },
                  '&:focus': {
                    outline: '2px solid',
                    outlineColor: theme => theme.palette.primary.main,
                  },
                  '&:active': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                  '&:disabled': {
                    color: 'rgba(0, 0, 0, 0.3)',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                  p: 1,
                  boxShadow: 2, 
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Box className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
              {customTemplateRules.length > 0 && customTemplateRules.map((template, index) => (
                <CustomRule 
                  key={index}
                  rule={template}
                  onSaveToggle={handleSaveToggle}
                  onOpenDrawer={handleDrawerOpen}
                  onEditTemplate={handleEditTemplateRule}
                />
              ))}
              {customRules.length > 0 && customRules.map((rule) => (
                <CustomRule
                  key={rule.id}
                  rule={rule}
                  onEdit={handleEditClick}
                  onRemove={handleRemoveClick}
                />
              ))}
            </Box>
          </>
        )}
        <Snackbar
          open={showConfirm}
          autoHideDuration={6000}
          onClose={handleCancelRemove}
          message={`Are you sure you want to remove ${selectedRule ? selectedRule.title : ''}?`}
          action={
            <>
              <Button color="inherit" size="small" onClick={handleCancelRemove}>
                Cancel
              </Button>
              <Button color="inherit" size="small" onClick={handleConfirmRemove}>
                Confirm
              </Button>
            </>
          }
          sx={{ bottom: { xs: 70, sm: 10 } }}
        />
        { selectedTemplateID && (
          <RuleTemplateDrawer open={openDrawer} onClose={handleDrawerClose} templateID={selectedTemplateID} editTemplateRule={isEditTemplateRule} />
        )}
      </Box>
    </div>
  );
};

export default Rules;
