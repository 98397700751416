import { Box, Typography } from '@mui/material';
import BarCharts from '../../charts/BarCharts';
import ImageBlock from '../../CountBlock'; 
import FeatureRequests from '../../FirstThemeView';
import { formatNumber, isValidSentimentData } from '../../../helper/helper';
import feature from '../../../assets/icons/gear.png';
import bug from '../../../assets/icons/bugs.png';
import { useFeedbackQContext } from '../../../context/FeedbackQ';
import SkeltonGraph from '../../utils/shimmerUI/SkeletonGraph';

const FirstViewLayout = ({ themeAnalysis, sentimentData }) => {
    const { loadingAnalysis } = useFeedbackQContext();
    const hasSentimentData = isValidSentimentData(sentimentData);
    return (
        <Box className="container mx-auto p-4">
            <Box className="grid grid-cols-1 md:grid-cols-3 gap-2">
                {themeAnalysis?.cardData?.length > 0 && (
                    <>
                        <ImageBlock title="Feature requests" count={formatNumber(themeAnalysis?.cardData[1]?.no_of_new_feature_requests ?? 0)} percentage={`${themeAnalysis?.cardData[1]?.feature_percentage}%`} />
                        <ImageBlock title="Bug reports" count={formatNumber(themeAnalysis?.cardData[2]?.no_of_bugs ?? 0)} percentage={`${themeAnalysis?.cardData[2]?.bugs_percentage}%`} />
                    </>
                )}
                <Box className="flex-grow" />
            </Box>

            {loadingAnalysis ? (
                    <SkeltonGraph />
            ) : !hasSentimentData ? (
                <Box className="mt-6 p-6 bg-white rounded-lg shadow-md">
                <Typography variant="h6" align="center">
                    No sentiment data available
                </Typography>
                </Box>
            ) : (
                <Box className="mt-8 p-6 bg-white rounded-lg shadow-md">
                <Typography variant="h5" sx={{ mb: 3 }}>
                    Monthly Volume by Sentiment
                </Typography>
                <BarCharts
                    data={sentimentData}
                    title="Top 5 Themes"
                    colors={["#7df587", "#db7087", "#4f4f4f"]}
                />
                </Box>
            )}

            <Box className='flex flex-col md:flex-row gap-6 mt-8'>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                    <FeatureRequests type="NewFeatureRequests" icon={feature} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                    <FeatureRequests type="Bugs" icon={bug} />
                </Box>
            </Box>
        </Box>
    );
};

export default FirstViewLayout;
