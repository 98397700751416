import { Box} from '@mui/material';
import { useEffect, useState } from 'react';
import APIDescription from './APIDataSource/APIDescription';
import { fetchAPISourceToken } from '../../api/API';
import SDKUsageDescription from './APIDataSource/SDKUsageDescription';
import APIUsageBlock from './APIDataSource/APIUsageBlock';
import APIRequestBlock from './APIDataSource/APIRequestBlock';
import APIResponseBlock from './APIDataSource/APIResponseBlock';

const APIDataSource = () => {
  const [apiKey, setApiKey] = useState('');
  const [apiUrl, setApiUrl] = useState('https://feedbackq.insightq.ai/api/v1/reviews')

  useEffect(() => {
    const getAPIDetailes = async () => {
      try {
        const dashboard_id = localStorage.getItem("dashboardId");
        const response = await fetchAPISourceToken(dashboard_id);
        console.log('response is ',response);
        setApiKey(response.api_key);
        setApiUrl(response.api_url);
      } catch (error) {
        console.log(error);
      }
    };
    getAPIDetailes();
  }, []);

  

  return (
    <>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <APIDescription />
      <SDKUsageDescription apiKey={apiKey} apiUrl={apiUrl} setApiKey={setApiKey}  />
      <APIUsageBlock/>
      <APIRequestBlock />
      <APIResponseBlock />
    </Box>
    </>
  );
};

export default APIDataSource;
