import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Box,
  TextField,
  IconButton,
  Grid,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import axios from 'axios';
import { analyzeReviews, saveCsvReviews } from "../api/API";

import NotificationAlert from "./NotificationAlert";
import { useFeedbackQContext } from "../context/FeedbackQ";

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
  position: "relative",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1),
  top: theme.spacing(1),
  color: "gray",
}));

const SelectBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

const SelectField = styled(Box)(({ theme }) => ({
  width: "45%",
}));

const FieldMappingPopup = ({
  open,
  onClose,
  csvHeaders,
  targetFields,
  onFieldChange,
  onSave,
  mappedFields,
  onFieldDelete,
  onCancel,
  setMappedFields,
}) => {
  const {
    setAlertText,
    setUpdateDataSource,
    setLoadingData,
    setLoading,
    setIsAnalyzing,
    setProgressMessage,
    setIsDisabled,
  } = useFeedbackQContext();

  const handleSave = async () => {
    try {
      const dashboardID = localStorage.getItem("dashboardId");

      const orgID = sessionStorage.getItem('orgId')
      const response = await saveCsvReviews(mappedFields, dashboardID, orgID);
      // console.log("mapped: ", mappedFields);
      // const response =await axios.post('http://localhost:7070/upload/savemappedfields', mappedFields);
      // console.log("Mapped fields saved successfully.", response);
      setLoading(true); //loader
      setLoadingData("Fetching reviews from csv.Please wait....");
      onSave();
      setLoading(false); //loader

      //khairunnisa
      setAlertText("App created/updated. Reviews fetching and analyzing");
      // console.log("Alert from csv");

      //Add the datasource update
      setUpdateDataSource(true);

      //disable button
      setIsDisabled(true);

      //progress
      setIsAnalyzing(true);
      setProgressMessage("Reviews are analysing from csv....");
      // console.log("Analysis started. Progress bar shown from csv");

      // Call the analyze-and-save endpoint
      const analyzeResponse = await analyzeReviews(dashboardID);
      // const analyzeResponse = await axios.post('http://localhost:7070/analysis/analyze-data');
      // console.log("Data analysis and save completed.", analyzeResponse);

      //progress
      setProgressMessage("Reviews analysed successfully of csv.");

      // Trigger any additional save actions
    } catch (error) {
      console.error("Error saving mapped fields or analyzing data:", error);
      setProgressMessage("Error analyzing reviews from csv.");
    } finally {
      // Ensure that this is always executed
      setIsAnalyzing(false);
      setIsDisabled(false);
      // console.log("Analysis completed. Progress completed from csv");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Title>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        Map CSV Fields
      </Title>
      <DialogContent>
        <Container>
          <Grid container>
            {Object.keys(mappedFields).map((header, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={header}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={mappedFields[header]}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => onFieldChange(header, "")}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onFieldDelete(header)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <SelectBox marginTop={2}>
            <SelectField>
              <Select
                value=""
                onChange={(e) => onFieldChange(e.target.value, "")}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem value="" disabled>
                  Select CSV Field
                </MenuItem>
                {csvHeaders
                  .filter(
                    (header) => !Object.keys(mappedFields).includes(header)
                  )
                  .map((header, idx) => (
                    <MenuItem key={idx} value={header}>
                      {header}
                    </MenuItem>
                  ))}
              </Select>
            </SelectField>
            <SelectField>
              <Select
                value=""
                onChange={(e) => onFieldChange("", e.target.value)}
                displayEmpty
                fullWidth
                variant="outlined"
                disabled={!Object.values(mappedFields).includes("")}
              >
                <MenuItem value="" disabled>
                  Select Target Field
                </MenuItem>
                {targetFields
                  .filter(
                    (field) => !Object.values(mappedFields).includes(field)
                  )
                  .map((field, idx) => (
                    <MenuItem key={idx} value={field}>
                      {field}
                    </MenuItem>
                  ))}
              </Select>
            </SelectField>
          </SelectBox>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldMappingPopup;
