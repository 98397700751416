import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: '"Roboto", sans-serif', // Set your desired font here
    },
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundcolor: '#f5f5f5',
                        cursor: 'pointer',
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'center',
                    listStyle: 'none'
                },
            },
        },
    },
})