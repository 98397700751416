import React from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Chip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { apiResponseData } from "../../../helper/staticContent";

const APIResponseBlock = () => {
    return (
        <Box className="p-4 bg-gray-100 rounded-md shadow-lg">
            <Typography variant="h5" className="font-bold mb-4">
                Response
            </Typography>

            {apiResponseData.map((response) => (
                <Accordion key={response.statusCode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${response.statusCode}-content`}
                        id={`panel${response.statusCode}-header`}
                    >
                        <Chip label={response.statusCode} color={response.color} className="mr-2" />
                        <Typography>{response.label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{response.description}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default APIResponseBlock;


