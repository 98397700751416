import { Skeleton } from "@mui/material"

export default function SkeletonDashboard() {
  return (
    <div className="bg-blue-50 p-6 min-h-screen">
      <div className="max-w-6xl mx-auto space-y-6">
        {/* Header */}
        <div className="space-y-2">
          <Skeleton variant="text" width={300} height={40} />
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="80%" height={20} />
        </div>

        {/* Top row of cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="bg-white p-4 rounded-lg shadow">
              <Skeleton variant="text" width={100} height={24} />
              <Skeleton variant="text" width={80} height={40} />
              <Skeleton variant="text" width={40} height={16} />
            </div>
          ))}
        </div>

        {/* Second row of cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="bg-white p-4 rounded-lg shadow">
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={80} height={24} />
              <Skeleton variant="text" width={60} height={40} />
              <Skeleton variant="text" width={40} height={16} />
            </div>
          ))}
        </div>

        {/* Bottom row of larger cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-white p-4 rounded-lg shadow">
              <Skeleton variant="text" width={200} height={24} />
              <div className="mt-4">
                <Skeleton variant="rectangular" width="100%" height={120} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}