import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icons from "./Icons";
import { BlockContainer, BlockTitle } from "../muitheme/StyledComponents";

const CreateCollectFeedbackCard = ({ iconType, title, description, disabled, onClick,disabledMessage, cardActive=false }) => {
    return (
        <BlockContainer
            onClick={disabled ? null : onClick}
            sx={{
                width: '300px',
                // height:'110px',
                padding: '15px 20px 20px 20px',
                border: 'solid 1px lightgray',
                borderRadius: "10px",
                cursor: disabled ? 'not-allowed' : "pointer",
                position: 'relative',
                overflow: 'hidden',
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": disabled ? {
                    "&::before": {
                        // content: "'Coming Soon'",
                        content: `'${!disabledMessage ?"Coming Soon" : ''}'`,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '1.5em',
                        color: 'rgba(0, 0, 0, 0.7)',
                    },
                    "& .cardContent": {
                        filter: 'blur(2px)',
                    },
                    "& .disabledMessage": {
                        filter: 'none'
                    },
                } : {
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transform: "translateY(-4px)",
                },
                "&:active": disabled ? {} : {
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transform: "translateY(2px)",
                },
                opacity: disabled ? 0.8 : 1,
            }}
            className={`transition-all duration-300 ease-in-out shadow-sm ${cardActive && disabled ? 'bg-gray-100' : 'bg-white hover:shadow-lg'}`}
        >
            <Box
                className="cardContent"
                sx={{
                    filter: cardActive && disabled ? 'blur(2px)' : 'none',
                    transition: 'filter 0.3s ease-in-out',
                    zIndex: disabled ? 1 : 2,
                }}
            >
                <Typography variant="h6" gutterBottom className="flex items-center text-gray-800">
                    {Icons[iconType]}
                    <span className="ml-1">{title}</span>
                </Typography>
                <Typography variant="body1" gutterBottom className="text-gray-600">
                    {description}
                </Typography>
            </Box>
            {/* disabledMessage */}
            {disabled && disabledMessage && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '10px 15px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        zIndex: 3,
                        width: '80%',
                        textAlign: 'center',
                        pointerEvents: 'none',
                    }}
                    className='text-gray-700 text-lg disabledMessage'
                >
                    <BlockTitle>
                        {disabledMessage}
                    </BlockTitle>
                </Box>
            )}
        </BlockContainer>
    );
};

export default CreateCollectFeedbackCard;
