import { Grid } from "@mui/material";

const useCardGrid = (data, CardComponentType)=>{
    return(
        <Grid container spacing={4} sx={{ marginY: { xs: 2, sm: 3 } }}>
            {data.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index} sx={{ marginY: { xs: 2, sm: 3, md: 3, lg: 1 } }}>
                    <CardComponentType {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

export default useCardGrid;