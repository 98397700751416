import React from 'react';
import { Box, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const ProgressBar = React.memo(({ value }) => {
  const valueInPercent = value * 100;

  return (
    <Box 
      sx={{
        border: `1px solid ${defaultTheme.palette.divider}`,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 26,
        borderRadius: 2
      }}
    >
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          lineHeight: '24px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          color: defaultTheme.palette.text.primary
        }}
      >
        {`${valueInPercent.toLocaleString()} %`}
      </Typography>
      <Box
        sx={{
          height: '100%',
          backgroundColor: valueInPercent < 10
            ? '#f44336' 
            : valueInPercent >= 10 && valueInPercent <= 50
            ? '#efbb5a'
            : '#088208', 
          width: `${valueInPercent}%`,
          transition: 'width 0.3s ease-in-out'
        }}
      />
    </Box>
  );
});

export default ProgressBar;
