import { Box } from "@mui/material";
import { Chart } from 'react-google-charts'

const BarCharts = ({ data, title, colors= ''})=>{
    const options = {
        title: title || 'Data Analysis',
        chartArea: { width: '70%'},
        isStacked: true,
        hAxis: {
            title: 'Value',
            minValue: 0,
        },
        vAxis: {
            title: 'Category'
        },
        colors: colors || ['#97dde8'],
        bar: {
            groupWidth: "70%"
        },
        legend: { position: 'top'}
    }
    return(
        <>
            <Box sx={{ width: '100%', height: { xs: '300px', sm: '400px' } }}>
                <Chart 
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            </Box>
        </>
    )
}

export default BarCharts;