import { Box, Typography } from '@mui/material'
import { Chart } from 'react-google-charts'

export default function NPXChart() {

  const data = [
    ['Month', 'NPS Score', 'No. of Responses'],
    ['JAN 01', 20, 65],
    ['JAN 02', 55, 65],
    ['JAN 03', 30, 80],
    ['JAN 04', 28, 60],
    ['JAN 05', 45, 70],
    ['MAY', 58, 70],
    ['JUN', 35, 90],
    ['JUL', 55, 65],
    ['AUG', 48, 65],
    ['SEP', 58, 75],
    ['OCT', 70, 85],
  ]

  const options = {
    title: 'NPS Score and No. of Responses',
    titleTextStyle: { color: '#757575', fontSize: 16 },
    seriesType: 'bars',
    series: {
      0: { type: 'line', color: '#8e24aa', targetAxisIndex: 0 },
      1: { color: '#e1bee7', targetAxisIndex: 1 },
    },
    legend: { position: 'top', alignment: 'end' },
    chartArea: { width: '80%', height: '70%' },
    vAxes: {
      0: { title: 'NPS Score', viewWindow: { min: 0, max: 100 } },
      1: { title: 'No. of Responses', viewWindow: { min: 0, max: 100 } },
    },
    hAxis: { title: 'Month' },
    backgroundColor: { fill: 'transparent' },
  }

  return (
    <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="div">
            Net Promoter Score (NPS)
        </Typography>
      </Box>
      <Chart
        chartType="ComboChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </Box>
  )
}