import { Typography, Box, Drawer, Fab, Grid } from '@mui/material';
import PieCharts from '../../charts/PieCharts';
import AreaChart from '../../charts/AreaCharts';
import ReviewUnderTheme from '../ReviewUnderTheme';
import { useEffect, useState } from 'react';
import SkeletonHeader from '../../utils/shimmerUI/SkeletonHeader';
import ListSection from './ListSection';
import { fetchThemeDetailes, fetchThemeAnomaly, fetchActionDevItems } from '../../../api/API';
import { transformDataThemesReviewChart, transformSentimentData, transToxicityLevelData } from '../../../helper/helper';
import MarkdownContainer from '../../Markdown/MarkdownContainer';
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { useFeedbackQContext } from '../../../context/FeedbackQ';

const ThemeDrawer = ({ drawerOpen, handleCloseDrawer, themeData }) => {
    const [theme, setTheme] = useState(null);
    const [areaChartData, setAreaChartData] = useState({ data: [], title: '' })
    const [pieChartData, setpieChartData] = useState([])
    const [selectedReview, setSelectedReview] = useState(null);
    const [reviewDrawerOpen, setReviewDrawerOpen] = useState(false);
    const [anomaly, setAnomaly] = useState("");
    const [action_dev_item, setActionDevItem] = useState({ action_item: [], dev_activity: [] })
    const [toxicityLevelPercentage, setToxicityLevelPercentage] = useState([]);
    const [anomalyLoading, setAnomalyLoading] = useState(false);
    const [anomalyError, setAnomalyError] = useState(null);
    const [actionDevItemsLoading, setActionDevItemsLoading] = useState(false);
    const [actionDevItemsError, setActionDevItemsError] = useState(null);
    const navigate = useNavigate()
    const { ruleCheck } = useFeedbackQContext()
    useEffect(() => {
        const getThemeDetailes = async () => {
            if (themeData && themeData.id) {
                const dashboard_id = localStorage.getItem('dashboardId')
                const { themeDetails, themeDetailCharts } = await fetchThemeDetailes(themeData?.id, dashboard_id);
                console.log('theme details ',themeDetails[0].rules);
                setTheme(themeDetails || []);
                const convertedData = transformDataThemesReviewChart(themeDetailCharts);
                const convertedPieData = transformSentimentData(themeDetailCharts.sentiment_percentage);
                const convertedToxicityData = transToxicityLevelData(themeDetailCharts)
                setAreaChartData(convertedData)
                setpieChartData(convertedPieData);
                setToxicityLevelPercentage(convertedToxicityData)
            }
        }
        getThemeDetailes();
    }, [themeData, ruleCheck]);


    useEffect(() => {
        const getThemeAnomaly = async () => {
            if (themeData && themeData.id) {
                setAnomalyLoading(true);
                setAnomalyError(null);
                try {
                    const anomaly = await fetchThemeAnomaly(themeData?.id);
                    // console.log("Anomoly result: ",anomaly)
                    setAnomaly(anomaly?.anomaly_result);
                } catch (error) {
                    setAnomalyError('Failed to fetch anomalies');
                } finally {
                    setAnomalyLoading(false);
                }
            }
        };
        getThemeAnomaly();
    }, [themeData]);


    useEffect(() => {
        const getActionDevItems = async () => {
            if (themeData && themeData.id) {
                setActionDevItemsLoading(true);
                setActionDevItemsError(null);
                try {
                    const action_dev_item = await fetchActionDevItems(themeData?.id);
                    setActionDevItem(action_dev_item?.action_dev_result);
                } catch (error) {
                    setActionDevItemsError('Failed to fetch action development items');
                } finally {
                    setActionDevItemsLoading(false);
                }
            }
        };
        getActionDevItems();
    }, [themeData]);

    const handleOpenReviewDrawer = (review) => {
        setSelectedReview(review)
        setReviewDrawerOpen(true);
    }

    const handleAddClick = () => {
        navigate('/rules');
    }

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleCloseDrawer}
            PaperProps={{
                sx: {
                    width: '80%',
                    padding: '16px',
                },
            }}
        >
            <Box className="container border p-4">
                {theme && theme.length > 0 ? (
                    <Box>
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1 },
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                                transform: 'translateY(-50%)',
                                zIndex: 1000
                            }}
                        >
                            <Fab color='primary' aria-label='add' onClick={handleAddClick}>
                                <AddIcon />
                            </Fab>
                        </Box>
                        <Box className='container mx-auto my-6 p-4'>
                            <Box className='flex flex-col md:flex-row md:items-start justify-between mb-4'>
                                <Typography variant="h5" className='p-4 flex-shrink-0 md:w-3/4'>
                                    {theme[0]?.title || ''}
                                </Typography>
                                <Box className='flex flex-wrap gap-4 md:w-1/6'>
                                    <Box className='border border-gray-300 rounded-lg p-4 shadow-md flex-grow text-center'>
                                        <Typography variant='h6'>{theme[0]?.reviews_count || ''}</Typography>
                                        <Typography variant='body2' color='textSecondary'>Total Reviews</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='container mx-auto my-6 p-4'>
                            <Box className='flex flex-col md:flex-row gap-4'>
                                <Box className='flex-1 border border-gray-300 rounded-lg shadow-md h-[400px] overflow-y-auto'>
                                    <Typography variant='h5' className='p-4 border-gray-300 text-center'>
                                        Theme Summary
                                    </Typography>
                                    <Box className='p-4'>
                                        <Typography variant='body2' color='textSecondary'>
                                            {theme[0]?.description || ''}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='flex-1 border border-gray-300 rounded-lg shadow-md h-[400px]'>
                                    <Typography variant='h5' className='p-4 border-gray-300 text-center'>
                                        Sentiment Distribution
                                    </Typography>
                                    <Box className='flex items-center justify-center mt-5 ml-3'>
                                        <PieCharts data={pieChartData} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='flex flex-col md:flex-row gap-4 my-6'>
                                {actionDevItemsLoading ? (
                                    <SkeletonHeader />
                                ) : actionDevItemsError ? (
                                    <Typography variant='body2' color='error'>
                                        {actionDevItemsError}
                                    </Typography>
                                ) : (
                                    <>
                                        <ListSection
                                            title={action_dev_item?.action_item?.length > 1 ? 'Action Items' : 'Action Item'}
                                            items={action_dev_item?.action_item}
                                            loading={false}
                                        />
                                        <ListSection
                                            title={action_dev_item?.dev_activity?.length < 1 ? 'Dev Activity' : 'Dev Activities'}
                                            items={action_dev_item?.dev_activity}
                                            loading={false}
                                        />
                                    </>
                                )}
                            </Box>
                            <Box className="container mx-auto my-6 p-4">
                                <Box className="flex-1 border border-gray-300 rounded-lg shadow-md min-h-[300px] overflow-y-auto">
                                    <Typography variant='h5' className='p-4 border-gray-300'>
                                        Feedback Anomalies
                                    </Typography>
                                    <Box className='flex items-center justify-center my-6'>
                                        {anomalyLoading ? (
                                            <SkeletonHeader />
                                        ) : anomalyError ? (
                                            <Typography variant='body2' color='error'>
                                                {anomalyError}
                                            </Typography>
                                        ) : (
                                            <MarkdownContainer content={anomaly?.anomalies || anomaly} />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="container mx-auto my-6 p-4">
                            <Box className="flex flex-col md:flex-row gap-4">
                                <Box className='flex-1 border border-gray-300 rounded-lg shadow-md h-[400px]'>
                                    <Typography variant='h5' className='p-4 border-gray-300'>
                                        Feedback Counts
                                    </Typography>
                                    <Box className='flex items-center justify-center my-6'>
                                        <AreaChart data={areaChartData.data} chartTitle={areaChartData.title} />
                                    </Box>
                                </Box>
                                <Box className='flex-1 border border-gray-300 rounded-lg shadow-md h-[400px]'>
                                    <Typography variant='h5' className='p-4 border-gray-300 text-center'>
                                        Toxicity Distribution
                                    </Typography>
                                    <Box className='flex items-center justify-center mt-5 ml-3'>
                                        <PieCharts data={toxicityLevelPercentage} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="container mx-auto my-6 p-4">
                            <Grid container spacing={2}>
                                {theme[0]?.rules?.map((rule) => (
                                    <Grid item md={6} key={rule.rule_id}>
                                        <Box className="flex-1 border border-gray-300 rounded-lg shadow-md h-[400px] overflow-y-auto">
                                            <Typography variant="h5" className="p-4 border-gray-300 text-center">
                                                {rule.title}
                                            </Typography>
                                            <Box className="p-4">
                                                <Typography variant="body2" color="textSecondary">
                                                    {rule.analysis_result}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        <Box className="container mx-auto my-6 p-4">
                            <Box className='flex-1 border border-gray-300 rounded-lg shadow-md'>
                                <ReviewUnderTheme review={[]} handleOpenReviewDrawer={handleOpenReviewDrawer} />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <SkeletonHeader />
                )}
            </Box>
        </Drawer>
    )
}

export default ThemeDrawer;