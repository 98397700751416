import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchDataSources } from '../../api/API';
import { useFeedbackQContext } from '../../context/FeedbackQ';

const DatasourceTable = () => {
  const [data, setData] = useState([]);
  const { updateDataSource, setUpdateDataSource, dashboardCheck, hasApps, setHasApps } = useFeedbackQContext();

  useEffect(() => {
    const getData = async () => {
      const dashboard_id = localStorage.getItem('dashboardId');
      const fetchedData = await fetchDataSources(dashboard_id);
      setData(fetchedData.datasources || []);
      setUpdateDataSource(false);
      // if(fetchedData.datasources.length > 0){
      //   setHasApps(true);
      // }else{
      //   setHasApps(false);
      // }
    };
    getData();
  }, [updateDataSource, dashboardCheck]);

  return (
    <TableContainer component={Paper}>
      {data.length === 0 ? (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} align="center">
                No data available
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>App</TableCell>
              <TableCell align="right">Source</TableCell>
              <TableCell align="right">Added On</TableCell>
              <TableCell align="right">Review Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.app}
                </TableCell>
                <TableCell align="right">{
                  row.source === 'play_store' 
                  ? "Play Store" 
                  : 
                  row.source === 'app_store' 
                  ? 'App Store' 
                  : 
                  row.source === 'csv' 
                  ? 'CSV' 
                  : row.source === 'api' 
                  ? 'API' 
                  : 'Unknown'}
                </TableCell>
                <TableCell align="right">{row.date_added}</TableCell>
                <TableCell align="right">{row.reviews_received_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default DatasourceTable;
