import { Box } from "@mui/material";
import Chart from "react-google-charts";
import { getAxisFormat } from "../../helper/helper";

const AreaChart = ({ data, chartTitle }) => {
    const options = {
        title: chartTitle || 'Feedback Count',
        hAxis: {
            title: chartTitle.includes('Monthly') ? 'Month' :
                chartTitle.includes('Weekly') ? 'Week' : 'Date',
            titleTextStyle: { color: '#333' },
            minValue: 0,
            format: getAxisFormat(chartTitle)
        },
        vAxis: {
            title: 'Feedback Count',
            minValue: 0,
            textStyle: {
                fontSize: 12,
                color: '#555',
            },
        },
        legend: { position: 'top' },
        colors: ['#1b9e77'],
        areaOpacity: 0.3,
        lineWidth: 2,
        chartArea: { width: '80%', height: '70%' },
        annotations: {
            alwaysOutside: true,
            highContrast: true,
        },
        series: {
            0: { color: '#1b9e77' },
        },
    };

    // console.log(data);

    return (
        <Box style={{ width: '100%', height: '200px' }}>
            <Chart
                chartType="AreaChart"
                data={data}
                options={options}
                width="100%"
                height="100%"
            />
        </Box>
    );
};

export default AreaChart;
