import React from 'react';
import { Box, LinearProgress, Typography, Card, CardContent } from '@mui/material';

const AnalyzeReviewsProgress = ({ message, isAnalyzing, onStop }) => {
  return (
    <Box sx={{ width: '100%', height: '200px', padding: 2 }}>
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>{message}</Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {isAnalyzing ? (
              // <LinearProgress sx={{ width: '100%' }} />
              <LinearProgress sx={{ width: '100%', '& .MuiLinearProgress-bar': { backgroundColor: 'black' } }} />
            ) : (
              <Typography color="error" sx={{ fontSize: '0.875rem' }}>
                Analyzing progress stopped. Not all reviews were analyzed.
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnalyzeReviewsProgress;


// import React from 'react';
// import { Box, LinearProgress, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

// const AnalyzeReviewsProgress = ({ message, isAnalyzing, onStop }) => {
//   return (
//     <Box sx={{ width: '50%', margin: '0 auto', mt: 4 }}>
//       <Card variant="outlined">
//         <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '200px' }}>
//           <Typography variant="h6">{message}</Typography>
//           <Box sx={{ flexGrow: 1 }} />
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
//             {isAnalyzing ? (
//               <LinearProgress sx={{ width: '100%' }} />
//             ) : (
//               <Typography color="error" mt={2} sx={{ fontSize: '0.875rem' }}>
//                 Analyzing progress stopped. Not all reviews were analyzed.
//               </Typography>
//             )}
//           </Box>
//         </CardContent>
//         {/* <CardActions>
//           <Button size="small" color="primary" onClick={onStop}>
//             Stop Analyzing
//           </Button>
//         </CardActions> */}
//       </Card>
//     </Box>
//   );
// };

// export default AnalyzeReviewsProgress;




// import React from 'react';
// import { Box, LinearProgress, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

// const AnalyzeReviewsProgress = ({ message, isAnalyzing, onStop }) => {
//   return (
//     <Box sx={{ width: '50%', margin: '0 auto', mt: 4 }}>
//     <Card variant="outlined">
//       <CardContent>
//         <Typography variant="h6">{message}</Typography>
//         <Box sx={{ width: '100%', mt: 2 }}>
//           {isAnalyzing ? (
//             <LinearProgress />
//           ) : (
//             <Typography color="error" mt={2}>
//               Analyzing progress stopped. Not all reviews were analyzed.
//             </Typography>
//           )}
//         </Box>
//       </CardContent>
//       {/* <CardActions>
//         <Button size="small" color="primary" onClick={onStop}>
//           Stop Analyzing
//         </Button>
//       </CardActions> */}
//     </Card>
//     </Box>
//   );
// };

// export default AnalyzeReviewsProgress;
