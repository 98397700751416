import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import insightQIcon from '../../assets/icons/favicon.png';

const FollowUpSearchBox = ({ query, setQuery, onSearch }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();  // Prevent default action to avoid form submission
      onSearch();
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Box
      className={`relative flex items-center ${isHovered ? 'w-full' : 'w-16'} transition-all duration-300`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TextField
        variant="outlined"
        placeholder="Search…"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={insightQIcon} alt='search' className='w-6 h-6' />
            </InputAdornment>
          ),
          sx: {
            width: '100%',
            height: '40px',
            background: 'white',
            borderRadius: '40px',
            '& .MuiOutlinedInput-root': {
              padding: '0',
              '& fieldset': {
                border: 'none',
              },
            },
            '& .MuiInputBase-input': {
              padding: '0 10px',
              transition: 'width 1.1s, padding 0.4s',
              width: isHovered ? '520px' : '0px',
              lineHeight: '40px',
            },
          },
        }}
      />
    </Box>
  );
};

export default FollowUpSearchBox;
