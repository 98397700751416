import { FormControl, FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";

const HeaderTriggerPoint = ({ selectedValue, onChange }) => (
    <FormControl component="fieldset">
        <RadioGroup row value={selectedValue} onChange={onChange}>
            <FormControlLabel value="new_reviews" control={<Radio />} label="When new reviews get added" />
            <FormControlLabel value="theme_summary" control={<Radio />} label="When theme summary is updated" />
        </RadioGroup>
    </FormControl>
);

export default HeaderTriggerPoint;
