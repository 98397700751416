import { Box, CircularProgress, Typography, Alert, Snackbar, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AnalysisBlock from "./AnalyzeBlock";
import RulesPrompt from "./RulePrompt";
import SampleOutput from "./SampleOutput";

import { createrules, sample_output, rules_accept, getRuleById, updateRule, getRuleByTempID } from "../../api/API";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomTemplate } from "../../helper/helper";
import { useFeedbackQContext } from "../../context/FeedbackQ";


const CreateRule = ({ templateID = null, editTemplateRule = false }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [title, setTitle] = useState("");
    const [headerTrigger, setHeaderTrigger] = useState("new_reviews");
    const [rules, setRules] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSample, setShowSample] = useState(false);
    const { id } = useParams();
    const [sampleOutput, setSampleOutput] = useState("");
    const [rules_prompt, setRules_prompt] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [defaultID, setDefaultId] = useState("");
    const [customId, setCustomId] = useState("")
    const { setRulebookmark, dashboardCheck, setRuleCheck, ruleCheck } = useFeedbackQContext();
    
    const navigate = useNavigate();
    useEffect(() => {
        const fetchExistingRule = async () => {
            try {
                setLoading(true);
                if (id) {
                    // Simulate successful fetch
                    const dashboard_id = localStorage.getItem("dashboardId");

                    const response = await getRuleById(id, dashboard_id);
                    console.log("response", response);
                    const data = {
                        title: response.title, // Replace with actual fetched data
                        rules: response.rules,  // Replace with actual fetched data
                        stpes: response.rules_prompt,
                        output: response.sample_output
                    };
                    setTitle(data.title || "");
                    setRules(data.rules || "");
                    setRules_prompt(data.stpes);
                    setSampleOutput(data.output);
                    setShowDetails(true);
                    setShowSample(true);
                    setIsEdit(true);
                }
            } catch (error) {
                setError("Failed to fetch rule details.");
            } finally {
                setLoading(false);
            }
        };
        fetchExistingRule();
    }, [id]);

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                setLoading(true);
                console.log("temp ID :: ", templateID)
                console.log("editTemplateRule :: ", editTemplateRule)
                const dashboard_id = localStorage.getItem("dashboardId");

                const response = await getRuleByTempID(dashboard_id, templateID)
                console.log("response 11 :: ", response)
                setCustomId(response._id)
                if (templateID && editTemplateRule) {
                    const data = {
                        title: response.title, // Replace with actual fetched data
                        rules: response.rules,  // Replace with actual fetched data
                        stpes: response.rules_prompt,
                        output: response.sample_output
                    };

                    setTitle(data.title || "");
                    setRules(data.rules || "");
                    setRules_prompt(data.rules);
                    setSampleOutput(data.output);
                    setShowDetails(true);
                    setShowSample(true);
                    setIsEdit(true);
                } else if (templateID && !editTemplateRule) {
                    const templates = getCustomTemplate();
                    const selectedTemplate = templates.find(template => template.defaultID === templateID);

                    if (selectedTemplate) {
                        setTitle(selectedTemplate.title);
                        setRules(selectedTemplate.content);
                    }
                }
            } catch (error) {
                setError("Error occurred while fetching template");
            } finally {
                setLoading(false);
            }
        };
        fetchTemplate();
    }, [templateID, editTemplateRule]);



    const handleHeaderChange = (event) => {
        setHeaderTrigger(event.target.value);
    };



    // Function to handle closing of snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        setTitle("");
        setRules("");
        setRules_prompt("");
        setSampleOutput('');
        setShowDetails(false);
        setShowSample(false);
        setIsEdit(false);
        navigate('/rules');
    };

    /// for the title and rules 
    const handleSubmit = async () => {
        try {
            const response = await createrules(title, rules);
            setRules_prompt(response);
            setShowDetails(true);

        } catch (error) {
            console.error(error.message);
        }
    };
    // for after prompt is generated and now sample output is required
    const handleAcceptPrompt = async () => {
        try {
            console.log("rules prompt : ", rules_prompt)
            const dashboard_id = localStorage.getItem("dashboardId");

            const response = await sample_output(title, rules, rules_prompt, dashboard_id);
            console.log(response);
            setSampleOutput(response);
            setShowSample(true);
        } catch (error) {
            console.error(error);
        }
    }
    const submitCreateRule = async () => {
        try {
            console.log("templateId", templateID)
            if (templateID) {
                setDefaultId(templateID);
            }
            // console.log(title, rules, rules_prompt, sampleOutput,headerTrigger,defaultID,dashboardId);
            //   const response = await rules_accept(title, rules, rules_prompt, sampleOutput,headerTrigger,templateID,dashboardId);
            const dashboard_id = localStorage.getItem("dashboardId");
            const requestData = {
                title: title,
                rules: rules,
                rules_prompt: rules_prompt,
                sample_output: sampleOutput,
                trigger_point: headerTrigger,
                // default_id:defaultID ,
                ...(templateID ? { default_id: templateID } : { default_id: "" }),
                dashboard_id: dashboard_id,
            };
            let response;
            if(id){
                console.log("id:: ", id);
                response = await updateRule(id, requestData);
            }
            else if(customId){
                console.log('customId:: ', customId)
                response = await updateRule(customId, requestData );
            }
            else{
                response = await rules_accept({ requestData });
                
            }
            if (response) {
                    setRulebookmark("bookmark");
                    setSnackbarMessage('Rule created successfully!');
                    setSnackbarSeverity('success');
                    setRuleCheck(!ruleCheck)
                } else {
                    setSnackbarMessage(`Submission failed: ${response.statusText}`);
                    setSnackbarSeverity('error');
                }
            
        } catch (error) {
            setSnackbarMessage(`Error during submission: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setOpenSnackbar(true);
        }
    };
    return (
        <Box className="container mx-auto mt-6 p-4 max-w-4xl">
            <Box className="flex flex-col items-start mb-4">
                <Typography variant="h4" gutterBottom>
                    Edit Rule
                </Typography>
            </Box>
            <Box className="p-4 bg-white rounded-lg">
                <AnalysisBlock
                    title={title}
                    rules={rules}
                    setTitle={setTitle}
                    setRules={setRules}
                    handleSubmit={handleSubmit}
                    showDetails={showDetails}
                    isEdit={isEdit}
                />
                {loading ? (
                    <Box className="flex justify-center items-center h-40">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error" className="mt-4">
                        {error}
                    </Alert>
                ) : showDetails && (
                    <Box className="mt-6 space-y-4">
                        <Box className="p-4 border border-gray-300 rounded-lg bg-gray-50">
                            <RulesPrompt
                                rulesPrompt={rules_prompt}
                                handleSubmit={handleAcceptPrompt}
                                headerTrigger={headerTrigger}
                                onHeaderChange={handleHeaderChange}
                                showSample={showSample}
                                isEdit={isEdit}
                            />
                        </Box>
                        {showSample && (
                            <Box className="p-4 border border-gray-300 rounded-lg bg-gray-50">
                                <SampleOutput sampleOutput={sampleOutput} submitCreateRule={submitCreateRule} />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message="Rule created successfully"
                action={
                    <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                        Close
                    </Button>
                }
            />
        </Box>
    );
};

export default CreateRule;