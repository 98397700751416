import { Box, TextField } from "@mui/material";
const RulesForm = ({ title, rules, setTitle, setRules }) => { 
   
    return (
        <Box className="mt-4">
            <Box className="my-2">
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="mb-4"
                />
            </Box>
            <Box className="my-2">
                <TextField
                    label="Rules"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    value={rules}
                    onChange={(e) => setRules(e.target.value)}
                    className="mb-4"
                />
            </Box>
        </Box>
    );
};

export default RulesForm;