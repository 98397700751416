import { Card, CardContent, Typography, Box } from "@mui/material";
import React from 'react';

// Define an Emoji based on the score
const getEmoji = (score) => {
  if (score >= 8) return "😊"; // Happy
  if (score >= 5) return "😐"; // Neutral
  return "😢"; // Sad
};

// Define a Progress Bar component
const ProgressBar = ({ value }) => {
  const valueInPercent = (value / 10) * 100;

  return (
    <Box
      sx={{
        border: '1px solid #ddd',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 20,
        borderRadius: 2,
        backgroundColor: '#f0f0f0',
        mt: 2,
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: `${valueInPercent}%`,
          backgroundColor: valueInPercent < 30
            ? '#f44336' // Red for low values
            : valueInPercent >= 30 && valueInPercent <= 70
            ? '#efbb5a' // Yellow for medium values
            : '#088208', // Green for high values
          transition: 'width 0.3s ease-in-out'
        }}
      />
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          width: '100%',
          lineHeight: '20px',
          textAlign: 'center',
          color: '#000',
        }}
      >
        {`${value}/10`}
      </Typography>
    </Box>
  );
};

const CustomerSatisfactionScore = ({ value }) => {
  return (
    <Card sx={{ borderRadius: 2, padding: 2, minWidth: 100, minHeight: 372 }}>
      <CardContent>
        <Typography variant="h6" className="font-bold text-gray-700 text-center">
          Customer Satisfaction Score
        </Typography>
        <Box className="mt-4 text-center">
          <Typography variant="h4" className="font-bold text-blue-600">
            {getEmoji(value)}
          </Typography>
          <Typography variant="h4" className="font-bold text-blue-600">
            {value}/10
          </Typography>
          <ProgressBar value={value} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomerSatisfactionScore;