import { Box, Divider, Typography } from "@mui/material";
import { ClickableItem } from "../../muitheme/StyledComponents";
import { preBuiltQuestions } from "../dummyData"

const PreBuiltQueries = ({onClickQuery})=>{
    // console.log(preBuiltQuestions);
    return(
        <Box sx={{
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: 2,
            boxShadow: 1,
            mt: 2,
            textAlign: 'left' // Ensure text alignment is left
        }}>
            <Typography variant="h6" gutterBottom>
                Need some inspiration?
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {preBuiltQuestions.map((question, index) => (
                <ClickableItem
                    key={index}
                    onClick={() => onClickQuery(question)}
                >
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        {question}
                    </Typography>
                </ClickableItem>
            ))}
        </Box>
        
    )
}

export default PreBuiltQueries;