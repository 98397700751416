import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { defaultRules } from '../../helper/helper';

const DefaultTitles = () => {
  return (
    <Grid container spacing={3} className="p-4">
      {defaultRules.map((title) => (
        <Grid item xs={12} sm={6} md={3} key={title}>
          <Paper
            className="p-3 flex items-center justify-center h-full shadow-sm rounded-lg transition-transform"
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 255, 0.05)', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              },
              transition: 'transform 0.3s'
            }}
          >
            <Typography
              variant="body1"
              className="text-center text-lg font-medium"
              sx={{
                color: 'text.primary',
                fontSize: '0.875rem' 
              }}
            >
              {title}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default DefaultTitles;


