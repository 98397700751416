import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { formatSentimentData } from "../helper/helper";
import { useFeedbackQContext } from "../context/FeedbackQ";
import { fetchThemeAndAnalysis } from "../api/API";
import FirstViewLayout from "./theme/firstview/FirstViewLayout";
import FeatureList from "./SecondThemeView";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";

const Themes = () => {
    const [sentimentData, setSentimentData] = useState([]);
    const [view, setView] = useState("first");
    const {
        setThemeError,
        setAnalysisError,
        setLoadingThemes,
        setLoadingAnalysis,
        setThemes,
        setThemeAnalysis,
        themeAnalysis,
        dashboardCheck,
    } = useFeedbackQContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingThemes(true);
                setLoadingAnalysis(true);
                const dashboard_id = localStorage.getItem("dashboardId");
                const { themes: fetchedThemes, themeAnalysis: fetchedThemeAnalysis } =
                    await fetchThemeAndAnalysis(dashboard_id);
                setThemes(
                    fetchedThemes.sort((a, b) => b.reviews_count - a.reviews_count)
                );
                setThemeAnalysis(fetchedThemeAnalysis);
            } catch (error) {
                console.error("Error occurred while fetching data:", error);
                if (error.message.includes("themes")) {
                    setThemeError(error);
                } else {
                    setAnalysisError(error);
                }
            } finally {
                setLoadingThemes(false);
                setLoadingAnalysis(false);
            }
        };

        fetchData();
    }, [dashboardCheck]);

    useEffect(() => {
        if (themeAnalysis && themeAnalysis?.barChartData) {
            setSentimentData(formatSentimentData(themeAnalysis?.barChartData));
        }
    }, [themeAnalysis]);

    const handleToggleView = () => {
        setView(view === "first" ? "second" : "first");
    };

    return (
        <div className="w-full max-w-7xl h-full text-black mx-auto p-4 sm:p-6 md:p-8 box-border mb-2 md:mb-6">
            <Box className="container mx-auto px-4 sm:px-6 md:px-8">
                <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
                    <IconButton color="primary" onClick={handleToggleView}>
                        {view === "first" ? <ViewListIcon /> : <GridViewIcon />}
                    </IconButton>
                </Box>

                <Box sx={{ width: '100%' }}>
                    {view === 'first' ? (
                        <FirstViewLayout
                            themeAnalysis={themeAnalysis}
                            sentimentData={sentimentData}
                        />
                    ) : (
                        <FeatureList />
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default Themes;
