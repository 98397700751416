import { Avatar, IconButton, Menu, MenuItem } from "@mui/material"
import { Logout } from '@mui/icons-material';
import { useState } from "react"
import { stringAvatar } from "../../helper/helper";
import { getColorForCharacter } from "../../helper/characterColor";

const ProfileAvatar = ({name})=>{
    const [anchorEl, setAnchorEl] = useState(null);
    
    
    const handleClick = (e)=>{
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () =>{
        let BASE_URL = process.env.REACT_APP_DJANGO_BASE_URL;
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            BASE_URL = 'https://insightq.ai';
        }
        localStorage.clear();
        window.location.href = `${BASE_URL}/logout`
    }

    return(
        <>
            <IconButton onClick={handleClick}>
                <Avatar {...stringAvatar(name)} sx={{ width: 24, height: 24, bgcolor: getColorForCharacter(name.charAt(0)) }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>
                    <Logout sx={{ mr: 1 }} />
                Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default ProfileAvatar;