import axios from "axios";

const api = axios.create({
  // baseURL: 'https://feedbackq-service.insightq.ai',
  baseURL: process.env.REACT_APP_APIBASEURL,
  // withCredentials: true,
})

// this baseurl is for local
// const api = axios.create({
//   baseURL: "http://127.0.0.1:7070",
// });

// Add a request interceptor to include the auth token in each request
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("auth_token");
    const orgId = sessionStorage.getItem("orgId");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["orgId"] = orgId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchThemeAndAnalysis = async (dashboard_id) => {
  try {
    const requests = [
      api.get(`/api/themes?dashboard_id=${dashboard_id}`),
      api.get(`/themes/display-themes?dashboard_id=${dashboard_id}`),
    ];

    const [themesResponse, analysisResponse] = await axios.all(requests);

    return {
      themes: themesResponse.data,
      themeAnalysis: analysisResponse.data,
    };
  } catch (error) {
    throw new Error(`Error occured while fetching data: ${error.message}`);
  }
};

export const fetchThemeDetailes = async (theme_id, dashboard_id) => {
  try {
    const themeDetail = await api.get("/theme-details/themes2", {
      params: { theme_id: theme_id },
    });
    // console.log("theme details :::  ", themeDetail)
    const themeDetailCharts = await api.get(`/feedback-events/reviewCount?dashboard_id=${dashboard_id}`, {
      params: { theme_id: theme_id },
    });
    const [themeDetailResponse, themeDetailChartResponse] = await Promise.all([
      themeDetail,
      themeDetailCharts,
    ]);
    return {
      themeDetails: themeDetailResponse.data,
      themeDetailCharts: themeDetailChartResponse.data,
    };
  } catch (error) {
    throw new Error(`Error occured while fetching data: ${error.message}`);
  }
};

export const fetchReviewsByThemeID = async (themeID, page, limit) => {
  try {
    const response = await api.get("/themesReviewDetails/reviews", {
      params: { themeID, page, limit },
    });
    console.log("reviewwwwwwwwww", response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Error occured while fetching reviews: ${error}`);
  }
};

export const createDashboard = async (workspace_name, description, org_id) => {
  try {
    const response = await api.post("/dashboard/create-dashboards", { dashboard_name: workspace_name, description, org_id });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while creating dashboard: ${error}`);
  }
};

export const fetchDashboards = async (orgId) => {
  try {
    const response = await api.get(`/dashboard/get-dashboards?org_id=${orgId}`);
    return response.data.dashboards;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching dashboards: ${error.message}`
    );
  }
};

export const getOrgData = async (orgId) => {
  try {
    const response = await api.get("/playstore/get-org-info", { params: { orgId: orgId } });
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching tokem details: ${error.message}`
    );
  }
};

export const getUsageData = async () => {
  try{
    const response = await api.get("/playstore/fetch-redis-datas");
    // console.log("response redis data :: ", response.data);
    return  response.data
  } catch (error){
    throw new Error(
      `Error occurred while fetching usage details: ${error.message}`
    );
  }
}

export const fetchAllReviews = async (
  page,
  limit,
  source = null,
  rating = null,
  category = null,
  dateRange = null,
  dashboard_id
) => {
  try {
    let params = {
      dashboard_id,
      page,
      limit,
      ...(dateRange && {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
    };
    if (source) params["source"] = source;
    if (rating) params["rating"] = rating;
    if (category) params["category"] = category;
    const response = await api.get("/allReviews/all-reviews", {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching all reviews: ${error.message}`
    );
  }
};

export const fetchThemeAnomaly = async (themeID) => {
  try {
    const response = await api.get("/actionItems/get-theme-anomaly", {
      params: { theme_id: themeID },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching theme: ${error.message}`);
  }
};

export const fetchActionDevItems = async (themeID) => {
  try {
    const response = await api.get("/actionItems/analyze-action-items", {
      params: { theme_id: themeID },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching theme: ${error.message}`);
  }
};

export const fetchDataSources = async (dashboard_id) => {
  try {
    const response = await api.get(`/dataSourcesDetails/datasources/?dashboard_id=${dashboard_id}`);
    // console.log("API response : ", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching data:",
      error.response ? error.response.data : error.message
    );
    return [];
  }
};

export const fetchPlayStoreREviews = async (appId, type, dashboard_id, orgId) => {
  try {
    const response = await api.post("/playstore/fetch-reviews", {
      app_id: appId,
      type: type,
      dashboard_id: dashboard_id,
      org_id: orgId
    });
    return response;
    // return await response.json();
  } catch (error) {
    throw new Error(`Error occurred while fetching reviews: ${error.message}`);
  }
};

export const analyzeReviews = async (dashboard_id) => {
  try {
    const response = await api.post(`/analysis/analyze-data?dashboard_id=${dashboard_id}`);
    return response;
  } catch (error) {
    throw new Error(`Failed to analyze and save data: ${error.message}`);
  }
};

export const fetchCsvReviews = async (formData) => {
  try {
    const response = await api.post("/upload/uploadcsv", formData);
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching reviews: ${error.message}`);
  }
};

export const saveCsvReviews = async (mappedFields, dashboard_id, orgId) => {
  try {
    const response = await api.post(
      `/upload/savemappedfields?org_id=${orgId}&dashboard_id=${dashboard_id}`,
      mappedFields
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while saving reviews: ${error.message}`);
  }
};

export const validatePlayStoreId = async (appId) => {
  try {
    const response = await api.get(`/validate/validate_play_store_id/${appId}`);
    return response.data.valid;
  } catch (error) {
    throw new Error(`Error occurred while validating ID: ${error.message}`);
  }
};

// export const validatePlayStoreId = async (appId) => {
//   try {
//     const url = `https://play.google.com/store/apps/details?id=${appId}`;
//     const response = await fetch(url, { method: 'GET', mode: 'no-cors' });
//     if (response.ok) {
//       return true
//     } else {
//       return false
//     }
//   } catch (error) {
//     throw new Error(`Error occurred while validating ID: ${error.message}`);
//   }
// };

export const createFeedback = async (feedback, dashboard_id, org_id) => {
  try {
    const response = await api.post(`/feedback/add?dashboard_id=${dashboard_id}`, {
      user_name: feedback.userName,
      rating: feedback.rating,
      content: feedback.content,
      org_id: org_id
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while creating feedback: ${error.message}`);
  }
};

export const checkDashboardName = async (dashboard_name, org_id) => {
  try {
    const response = await api.post(`/dashboard/check-dashboard-name/${dashboard_name}`, {}, { params: { orgId: org_id } })
    // console.log("response: ", response);
    return response.data.exists;
  } catch (error) {
    throw new Error(
      `Error occurred while checking dashboard name: ${error.message}`
    );
  }
};

export const getDashboardIdByName = async (dashboard_name, org_id) => {
  try {
    const response = await api.get(`/dashboard/get-dashboard-id/${dashboard_name}`, { params: { orgId: org_id } })
    // console.log(response);
    return response.data.id;
  } catch (error) {
    throw new Error(
      `Error occurred while fetching dashboard id: ${error.message}`
    );
  }
};


export const createrules = async (title, rules, templateID = null) => {
  try {
    let url = "/rules/rules_prompt";
    if (templateID) {
      url += `templateID=?${templateID}`;
    }
    const response = await api.post(url, { title, rules });
    // console.log(response.data) ;
    console.log("Rules_prompt is calling :", response);
    return response.data;
  } catch (error) {
    throw new Error(` 
      Error occurred while feteching rules_prompt : ${error.message}
      `);
  }
};

export const sample_output = async (title, rules, prompt, dashboard_id) => {
  try {
    console.log("promotttafasd : ", prompt)
    const response = await api.post("/rules/sample_output", {
      title,
      rules,
      prompt,
      dashboard_id
    });
    // console.log("sample_output is calling :", response);
    return response.data;
  } catch (error) {
    throw new Error(` 
      Error occurred while feteching rules_prompt : ${error.message}`);
  }
};

export const rules_accept = async ({ requestData }) => {
  try {
    const response = await api.post("/rules/accept", requestData)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
};

export const updateRule = async (id, requestData ) => {
  try {
    console.log("rule id",id);
    const response = await api.post(`/rules/update?rule_id=${id}`, requestData)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
};

export const getRuleByTempID = async (dashboard_id, tempId) => {
  try {
    const response = await api.get(`/rules/get-custom-rule?dashboard_id=${dashboard_id}&temp_id=${tempId}`)
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during accept/submitting the rule : ${error.message}`
    )
  }
}

export const custom_rules_bookmark = async (dashboard_id) => {
  try {
    const response = await api.get(`/rules/custombookmarkrules?dashboard_id=${dashboard_id}`);
    // console.log("customerulesapi is calling")
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const al_user_rules = async (dashboard_id) => {
  try {
    const response = await api.get(`/rules/userrules?dashboard_id=${dashboard_id}`);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const remove_user_rules = async (rule_id)=>{ 
  try {
    console.log("rule Id:", rule_id);
    const response = await api.post("/rules/deleterule", { rule_id: rule_id });
   return response.data;
  } catch (error) {
   throw new Error ( 
     `Error occured during feteching bookmark rules  : ${error.message}`
    )
  }
}

export const getRuleById = async (id, dashboard_id) => { 
  try {
    const response = await api.get(`/rules/edit?rule_id=${id}&dashboard_id=${dashboard_id}`);
    console.log("get rules by id", response.data);
    return response.data;

  } catch (error) {
    // Handle errors and throw a custom error message
    throw new Error(`Error occurred during fetching rule by ID: ${error.message}`);
  }
};


export const fetchAPISourceToken = async (dashboard_id) => {
  try {
    const response = await api.get('/dataSourcesDetails/datasource-api-token', { params: { dashboard_id } });
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching data sourec api token ${error}`)
  }
}

export const refreshAPISourceToken = async (dashboard_id) => {
  try {
    const response = await api.post('/dataSourcesDetails/datasource-api-token-refresh', { dashboard_id })
    return response.data;
  } catch (error) {
    throw new Error(`Error occurred while fetching refreshed data source api token ${error}`)
  }
}

