import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Divider, Pagination, Icon, CircularProgress } from '@mui/material';
import { useFeedbackQContext } from '../context/FeedbackQ';
import { CircleContainer } from '../muitheme/StyledComponents';
import ThemeDrawer from './theme/secondview/ThemeDrawer';

const Circle = ({ count, totalReviews }) => {
    const progressValue = totalReviews === 0 ? 0 : (count / totalReviews) * 100;
    return (
        <CircleContainer>
            <CircularProgress variant="determinate" value={progressValue} size={40} thickness={4} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.primary">
                    {count}
                </Typography>
            </Box>
        </CircleContainer>
    );
};

const ITEMS_PER_PAGE = 5;

const FeatureRequests = ({ type, icon }) => {
    const [featureRequests, setFeatureRequests] = useState([]);
    const [page, setPage] = useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { 
        getThemesByType, 
        themeError, 
        loadingThemes,
        themes,
        selectedItem,
        setSelectedItem
    } = useFeedbackQContext();

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (!loadingThemes && !themeError) {
            const data = getThemesByType(type);
            setFeatureRequests(data);
        }
    }, [themes]);

    const handleOpenDrawer = (item) => {
        setSelectedItem(item);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedItem(null);
    };

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = featureRequests.slice(startIndex, endIndex);
    const totalReviewsCount = currentItems.reduce((sum, request) => sum + request.reviews_count, 0);
    
    return (
        <Box 
            sx={{ 
                width: '100%', 
                maxWidth: { xs: '100%', sm: '650px' },
                bgcolor: 'background.paper', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                p: 2, 
                m: { xs: 1, sm: 5 },
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between', 
                minHeight: { xs: 'auto', sm: '590px' },
                fontSize: { xs: '0.875rem' },
            }}
        >
            <Typography variant="h6" gutterBottom display='flex' alignItems="center">
                <Icon sx={{ height: '27px', marginRight: "5px" }}>
                    <img src={icon} alt="feature" style={{ width: '24px', height: '24px' }} />
                </Icon>
                {type}
            </Typography>
            <List>
                { currentItems.length === 0 ? (
                    <Box className="flex flex-col items-center">
                        <Typography 
                            variant="body1" 
                            color="textSecondary" 
                            className="animate-fade-in-out"
                        >
                            No themes available
                        </Typography>
                    </Box>
                ) : (
                    currentItems.map((request,index) => (
                        <Box key={request.id} onClick={() => handleOpenDrawer(request)} className="cursor-pointer hover:bg-gray-100">
                            <ListItem divider sx={{ border: '1px solid #ccc', borderRadius: '8px', mb: 2, alignItems: 'center', gap: 2 }}>
                                <ListItemText
                                    primary={request.title}
                                    primaryTypographyProps={{ variant: 'body1' }}
                                    sx={{
                                        lineHeight: '1.5rem', fontWeight: 400, fontSize: '0.5rem'
                                    }}
                                />
                                <Circle count={request.reviews_count} totalReviews={totalReviewsCount} sx={{ mb: 2 }} />
                            </ListItem>
                            {index < currentItems.length - 1 && <Divider />}
                        </Box>
                    ))
                )}
            </List>
            <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'center' }}>
                { currentItems.length !==0 && (
                    <Pagination
                        count={Math.ceil(featureRequests.length / ITEMS_PER_PAGE)}
                        page={page}
                        onChange={handleChange}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                    />
                )}
            </Box>
            { selectedItem && (
                <ThemeDrawer drawerOpen={drawerOpen} handleCloseDrawer={handleCloseDrawer} themeData={selectedItem} />
            )}
        </Box>
    );
};

export default FeatureRequests;
