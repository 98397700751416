import { Card as MCard, CardContent, CardHeader } from "@mui/material";
import { formatNumber } from '../../helper/helper';
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const Card = ({ heading, content, sub }) => {
    const navigate = useNavigate();

    const handleHeaderClick = useCallback(() => {
        if (heading === 'Spams') {
            navigate('/reviews?category=Spams');
        }
    }, [heading, navigate]);

    return (
      <MCard 
        className="shadow-md rounded-lg h-[200px] mb-4 transform transition-transform duration-300 hover:scale-102 hover:shadow-lg" 
        sx={{
          cursor: heading === 'Spams' ? 'pointer' : 'default',
          backgroundColor: '#ffffff', // Set background to white
          transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transitions
          '&:hover': {
            transform: 'scale(1.02)', // Slight scale on hover
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // Subtle shadow on hover
          }
        }} 
        onClick={handleHeaderClick}
      >
        <CardHeader
          title={heading}
          titleTypographyProps={{
            variant: 'subtitle1', // Smaller variant for reduced font size
            sx: {
              fontWeight: 'bold',
              fontSize: '1rem', // Reduced font size
              color: '#333',
              textAlign: 'center',
              whiteSpace: 'nowrap', // Prevent text wrapping
              overflow: 'hidden', // Hide overflow text
              textOverflow: 'ellipsis', // Add ellipsis for overflow text
            }
          }}
          sx={{
            backgroundColor: '#ffffff',
            borderBottom: '1px solid #e0e0e0',
            padding: '12px', // Reduced padding
          }}
        />
          <CardContent className="flex flex-col items-center justify-center p-4 flex-grow">
              <div className="text-3xl font-bold text-center">
                  {formatNumber(content)}
              </div>
              {sub && (
                  <div className="text-xs text-gray-500 mt-1 text-center">
                      {sub}
                  </div>
              )}
          </CardContent>
      </MCard>
  );
};

export default Card;
